import { AttributeApiDto, ProductApiDto, SkuApiDto, SkuStateApiType } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ArrayElement } from 'type-fest/source/internal';

import { useProductsApi } from '../api/useProductsApi';
import { appConfig } from '../config';
import { ResourceKeys, t } from '../i18n/i18n';
import { useWishlistButtonHelper } from '../useWishlistButtonHelper';
import { formatCurrency } from '../util';
import { NumberInputProps, TextInputProps } from './fields/Input';
import { OptionProps } from './fields/Option';
import { RadioProps } from './fields/RadioCheck';
import { SelectProps } from './fields/Select';
import { TextAreaProps } from './fields/TextArea';
import { BaseUseFormProps, FormButtonProps, FormProps, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroupProps } from './FormGroup';
import { useForm } from './useForm';

export interface UseAddToCartFormProps
  extends Omit<BaseUseFormProps<FormValues, ValidationSchemaSelector>, 'defaultValues'> {
  imageSkuVariantAttributeTypeCode?: string;
  preselectedSkuId?: string;
  product: ProductApiDto;
  scope: 'product' | 'tile';
}

interface FormValues {
  giftCard?: GiftCardFormValues;
  // price: number; // togliere e derivare
  productVariantId: string;
  quantity: number;
  skuAttributes: Record<string, string>;
  skuId: string;
}

interface GiftCardFormValues {
  message: string;
  receiverEmail: string;
  receiverName: string;
  senderName: string;
}

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    availabilityEmailNotification: FormButtonProps;
    cancel?: FormButtonProps;
    quantity: {
      decrease: FormButtonProps;
      increase: FormButtonProps;
    };
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  giftCard?: {
    message: { formGroup: FormGroupProps; textArea: TextAreaProps<FormValues> };
    receiverEmail: { formGroup: FormGroupProps; textInput: TextInputProps<FormValues> };
    receiverName: { formGroup: FormGroupProps; textInput: TextInputProps<FormValues> };
    senderName: { formGroup: FormGroupProps; textInput: TextInputProps<FormValues> };
  };
  productVariants: {
    formFields: Array<{ productVariant: ProductApiDto; radio: RadioProps<FormValues> }>;
    formGroup: FormGroupProps;
  };
  quantity: { formGroup: FormGroupProps; numberInput: NumberInputProps<FormValues>; select: SelectProps<FormValues> };
  skuAttributes: Array<{
    attribute: {
      title: string;
      typeCode: string;
      value?: string;
    };
    formGroup: FormGroupProps;
    // radios: RadiosProps;
    radios: Array<{ radio: RadioProps<FormValues>; skus: Array<SkuApiDto> }>;
    select: SelectProps<FormValues>;
  }>;
  skus: {
    formFields: Array<{ option: OptionProps; radio: RadioProps<FormValues>; sku: SkuApiDto }>;
    formGroup: FormGroupProps;
    select: SelectProps<FormValues>;
  };
}

export const useAddToCartForm = ({
  imageSkuVariantAttributeTypeCode,
  onCancel,
  onSuccess,
  preselectedSkuId,
  product,
  scope,
  validationSchemaSelector,
}: UseAddToCartFormProps) => {
  // Gestisco il selectedProductVariant come variabile di stato in quanto devo memorizzare il risultato di una chiamata alle API
  const [selectedProductVariant, setSelectedProductVariant] = React.useState<ProductApiDto>(product);

  /**
   * Dato un prodotto, dice quale sku (eventualmente) preselezionare.
   */
  const getDefaultSkuId = React.useCallback(
    (_selectedProductVariant: ProductApiDto): string | undefined => {
      let _skuId = undefined;
      if (
        _selectedProductVariant.skus?.length === 1 &&
        (_selectedProductVariant.skus[0].state === 'AVAILABLE' || appConfig.enableProductAvailabilityEmailNotification)
      ) {
        _skuId = _selectedProductVariant.skus[0].id;
      }
      if (_skuId === undefined && preselectedSkuId !== undefined) {
        const preselectedSku = _selectedProductVariant.skus?.find((sku) => sku.id === preselectedSkuId);
        if (
          preselectedSku &&
          (preselectedSku.state === 'AVAILABLE' || appConfig.enableProductAvailabilityEmailNotification)
        ) {
          _skuId = preselectedSku.id;
        }
      }
      if (_skuId === undefined && appConfig.preselectPreferredSku) {
        const preferredSku = _selectedProductVariant.skus?.find((sku) => sku.preferred === true);
        if (
          preferredSku &&
          (preferredSku.state === 'AVAILABLE' || appConfig.enableProductAvailabilityEmailNotification)
        ) {
          _skuId = preferredSku.id;
        }
      }
      if (_skuId === undefined && appConfig.preselectFirstAvailableSku) {
        const firstAvailableSku = _selectedProductVariant.skus?.find((sku) => sku.state === 'AVAILABLE');
        if (firstAvailableSku) {
          _skuId = firstAvailableSku.id;
        }
      }
      return _skuId;
    },
    [preselectedSkuId]
  );

  const addToCartForm = useForm<FormValues>({
    defaultValues: {
      productVariantId: product.id,
      quantity: 1,
      skuAttributes: {},
      skuId: getDefaultSkuId(selectedProductVariant),
    },
  });

  const { setValue, watch } = addToCartForm;

  /**
   * Mi metto da parte la lista di tutte le varianti di prodotto,
   * con il prodotto stesso come primo elemento.
   */
  const productVariants = React.useMemo<Array<ProductApiDto>>(
    () => [{ ...product, variantProducts: undefined }, ...(product.variantProducts ?? [])],
    [product]
  );

  const handleSubmit: SubmitHandler<FormValues> = React.useCallback((values) => {
    console.log(values);
  }, []);

  const formHelper = React.useMemo<FormProps<FormValues>>(
    () =>
      ({
        className: 'add-to-cart-form',
        form: addToCartForm,
        onSubmit: handleSubmit,
      } satisfies FormProps<FormValues>),
    [addToCartForm, handleSubmit]
  );

  const isAtLeastOneSkuAvailable = React.useMemo(() => {
    return selectedProductVariant.skus?.some((sku) => sku.state === 'AVAILABLE');
  }, [selectedProductVariant.skus]);

  const { getProduct } = useProductsApi();

  /**
   * Gestisco cosa deve accadere al cambio della selezione sul prodotto.
   */
  const handleProductVariantIdChange = React.useCallback(
    (_productVariantId: string) => {
      console.log('handleProductVariantIdChange', _productVariantId);
      getProduct(_productVariantId, {
        populate:
          scope === 'product'
            ? { ...appConfig.api?.productPopulate, variantProducts: false }
            : { ...appConfig.api?.productTilePopulate, variantProducts: false },
      }).then((response) => {
        setSelectedProductVariant(response.data);
        const defaultSkuId = getDefaultSkuId(response.data);
        if (defaultSkuId) {
          setValue('skuId', defaultSkuId);
        }
      });
    },
    [getDefaultSkuId, getProduct, scope, setValue]
  );

  // /**
  //  * Gestisco cosa deve accadere al cambio della selezione su un attributo.
  //  */
  // const handleSkuAttributesChange = React.useCallback(
  //   (_skuAttributes: Record<string, string>) => {
  //     const possibleSkuChoices = getPossibleSkuChoices(undefined, skuAttributes);
  //     if (possibleSkuChoices.length === 1) {
  //       if (selectedProductVariant.skus && selectedProductVariant.skus.length > 1) {
  //         setValue('skuId', possibleSkuChoices[0].id);
  //       }
  //     } else {
  //       resetSkuIdSelection();
  //     }
  //   },
  //   [getPossibleSkuChoices, resetSkuIdSelection, selectedProductVariant.skus, setValue, skuAttributes]
  // );

  // const resetSkuIdSelection = React.useCallback(() => {
  //   setValue('skuId', '');
  //   // formikHelpers.setFieldTouched('skuId', false);
  // }, [setValue]);

  /*
   * Ho vari modi per mettermi in ascolto su un valore del form e fare qualcosa quando questo cambia:
   *  - Usare la watch passando il nome di un campo.
   *    Da usare quando si vuole stare in ascolto di QUALUNQUE variazione a un valore.
   *    es: const x = addToCartForm.watch('x')
   *    (Watch and subscribe to a single field used outside of render)
   *    pros:
   *      - Messo in una useEffect, parte sia all'inizializzazione, sia alla modifica.
   *        Non andrebbero tuttavia immpostati defaultValues in questo modo all'inizializzazione, in quanto una reset() non li considererebbe.
   *    cons:
   *      - Non riesco a distinguere una variazione avvenuta per diretta interazione del form o una indiretta come ad esempio una setValue
   *  - Usare la watch passando una callback.
   *    Da usare quando si vuole stare in ascolto solo di ALCUNE variazione a un valore.
   *    es: const subscription = watch((value, {name, type}) => {console.log(name)})
   *    (Subscribe to field update/change without trigger re-render)
   *    pros:
   *      - Riesco a distinguere una variazione in base al tipo di evento che l'ha scatenata (onChange, setValue, etc...)
   *    cons:
   *      - Parte solo alla modifica, non all'inizializzazione.
   *      - Non riesco a intercettare una variazione in seguito a una reset()
   *  - Implementare la onChange dentro registerOptions
   */

  console.count('rerender');

  const [productVariantId, skuId, skuAttributes] = watch(['productVariantId', 'skuId', 'skuAttributes']);

  /**
   * Mi metto in ascolto della variazione di productVariantId.
   * Quando varia, chiamo il relativo handler.
   */
  React.useEffect(() => {
    handleProductVariantIdChange(productVariantId);
  }, [handleProductVariantIdChange, productVariantId]);

  /**
   * Gestisco il selectedSku come come un dato derivato,
   * potendolo ottenere dal selectedProductVariant lo skuId selezionato nel form.
   */
  const selectedSku = React.useMemo<SkuApiDto | undefined>(
    () => selectedProductVariant.skus?.find((sku) => sku.id === skuId),
    [selectedProductVariant.skus, skuId]
  );

  /**
   * Mi sottoscrivo alla watch del form,
   * in modo da fare cose al cambiamento delle selezioni,
   * senza triggherare rerender.
   */
  React.useEffect(() => {
    const subscription = watch((value, { name, type, values }) => {
      console.log({ name, type, value, values });
      // if (name === 'productVariantId' && type === 'change' && value.productVariantId !== undefined) {
      //   handleProductVariantIdChange(value.productVariantId);
      // }
      // // intercetto solo un cambio diretto, il cambio indiretto per la modifica del prodotto è altrove.
      // if (name === 'skuId' && type === 'change') {
      //   setSelectedSku(selectedProductVariant.skus?.find((sku) => sku.id === value.skuId));
      // }
    });
    return () => subscription.unsubscribe();
  }, [selectedProductVariant.skus, watch]);

  const { handleWishlistButtonClick, inWishlist } = useWishlistButtonHelper({
    product: selectedProductVariant,
    sku: selectedSku,
  });

  /**
   * Metodo per avere tutti i possibili sku in base all'attuale selezione di attributi,
   * con la possibilità di escludere un certo attributo, per gestire i fratelli.
   */
  const getPossibleSkuChoices = React.useCallback(
    (attributeTypeCodeToExclude: string | undefined, skuAttributesValues: Record<string, string>) =>
      selectedProductVariant.skus?.filter((sku) => {
        return (
          Object.entries(skuAttributesValues)
            // filtro gli attributi per cui non ho ancora fatto una scelta.
            // filtro inoltre l'eventuale attributo da escludere
            .filter(
              ([attributeTypeCode, attributeValueCode]) =>
                attributeValueCode !== '' && attributeTypeCode !== (attributeTypeCodeToExclude ?? '')
            )
            .every(
              ([attributeTypeCode, attributeValueCode]) =>
                sku.attributes?.some(
                  (attribute) =>
                    attribute.typeCode &&
                    attribute.typeCode === attributeTypeCode &&
                    attribute.valueCode === attributeValueCode
                ) ?? false
            )
        );
      }) ?? [],
    [selectedProductVariant.skus]
  );

  /**
   * Dato un certo attributo, mi torna tutti gli sku ce lo contengono.
   */
  const getSkusByAttribute = React.useCallback(
    (attribute: AttributeApiDto) =>
      selectedProductVariant.skus
        ? selectedProductVariant.skus.filter((sku) =>
            sku.attributes?.some(
              (_attribute) => attribute.typeCode === _attribute.typeCode && attribute.valueCode === _attribute.valueCode
            )
          )
        : [],
    [selectedProductVariant.skus]
  );

  /**
   * Mappa che rappresenta gli sku (in origine a lista piatta) a mappa,
   * in base agli attributi.
   * Come chiave avrà "attribute.typeCode"
   * e come valore la lista di oggetti
   * contenente in ogni elemento il valore dell'attributo e tutti gli sku compatibili.
   *
   * Record<attribute.typeCode, Array<{attribute, skus}>>
   *
   * Esempio:
   *  - Taglia
   *    - sm (tutti gli sku sm)
   *    - md (tutti gli sku md)
   *  - Colore
   *    - bianco (tutti gli sku bianchi)
   *    - nero (tutti gli sku neri)
   */
  const skuVariantAttributesMap = React.useMemo<
    Record<string, Array<{ attribute: AttributeApiDto; skus: Array<SkuApiDto> }>>
  >(() => {
    const _skuVariantAttributesMap = selectedProductVariant.skus
      ? selectedProductVariant.skus.reduce<
          Record<string, Array<{ attribute: AttributeApiDto; skus: Array<SkuApiDto> }>>
        >((acc, currentValue) => {
          currentValue.attributes?.forEach((attribute) => {
            if (attribute.typeCode && attribute.valueCode) {
              if (!Object.keys(acc).includes(attribute.typeCode)) {
                acc[attribute.typeCode] = [];
              }
              if (!acc[attribute.typeCode].some((item) => attribute.valueCode === item.attribute.valueCode)) {
                acc[attribute.typeCode].push({ attribute: attribute, skus: getSkusByAttribute(attribute) });
              }
            }
          });
          return acc;
        }, {})
      : {};
    Object.values(_skuVariantAttributesMap).forEach((attributes) => {
      attributes.sort((a, b) => a.attribute.ordinal - b.attribute.ordinal);
    });
    return _skuVariantAttributesMap;
  }, [getSkusByAttribute, selectedProductVariant.skus]);

  /**
   *
   */
  const skuVariantAttributesMapByValueCode = React.useMemo<Record<string, AttributeApiDto>>(
    () =>
      selectedProductVariant.skus
        ? selectedProductVariant.skus.reduce<Record<string, AttributeApiDto>>((acc, _sku) => {
            _sku.attributes?.forEach((attribute) => {
              if (attribute.valueCode && !acc.hasOwnProperty(attribute.valueCode)) {
                acc[attribute.valueCode] = attribute;
              }
            });
            return acc;
          }, {})
        : {},
    [selectedProductVariant.skus]
  );

  const selectedSkuAttributes = React.useMemo<Record<string, AttributeApiDto | undefined>>(
    () =>
      Object.entries(skuAttributes).reduce<Record<string, AttributeApiDto>>(
        (acc, [attributeTypeCode, attributeValueCode]) => {
          acc[attributeTypeCode] = skuVariantAttributesMapByValueCode[attributeValueCode];
          return acc;
        },
        {}
      ),
    [skuAttributes, skuVariantAttributesMapByValueCode]
  );

  const getSkuAttributeAvailability = React.useCallback(
    (attribute: AttributeApiDto, skuAttributesValues: Record<string, string>): SkuStateApiType => {
      const possibleSkuChoices = getPossibleSkuChoices(attribute.typeCode, skuAttributesValues);
      const possibleSkuChoiceWithThisAttribute = possibleSkuChoices.filter((possibleSkuChoice) =>
        possibleSkuChoice.attributes?.some(
          (possibleSkuChoiceAttribute) =>
            possibleSkuChoiceAttribute.typeCode === attribute.typeCode &&
            possibleSkuChoiceAttribute.valueCode === attribute.valueCode
        )
      );
      return possibleSkuChoiceWithThisAttribute.length === 0
        ? 'NOT_AVAILABLE'
        : possibleSkuChoiceWithThisAttribute.length === 1
        ? possibleSkuChoiceWithThisAttribute[0].state
        : possibleSkuChoiceWithThisAttribute.some((sku) => {
            return sku.state === 'AVAILABLE';
          })
        ? 'AVAILABLE'
        : possibleSkuChoiceWithThisAttribute.some((sku) => {
            return sku.state === 'AVAILABLE_SOON';
          })
        ? 'AVAILABLE_SOON'
        : 'NOT_AVAILABLE';
    },
    [getPossibleSkuChoices]
  );

  const image = React.useMemo(
    () =>
      selectedSku
        ? selectedSku.image
        : imageSkuVariantAttributeTypeCode && selectedSkuAttributes[imageSkuVariantAttributeTypeCode] !== undefined
        ? selectedProductVariant.skus?.filter((sku) =>
            sku.attributes?.some(
              (attribute) => attribute.valueCode === selectedSkuAttributes[imageSkuVariantAttributeTypeCode]?.valueCode
            )
          )[0].image
        : product.image,
    [imageSkuVariantAttributeTypeCode, product.image, selectedProductVariant.skus, selectedSku, selectedSkuAttributes]
  );

  const fieldsHelper = React.useMemo<FieldsHelper>(
    () =>
      ({
        buttons: {
          availabilityEmailNotification: {
            label: inWishlist
              ? t('form.addToCartForm.buttons.availabilityEmailNotification.remove')
              : t('form.addToCartForm.buttons.availabilityEmailNotification.addLongLabel'),
            onClick: handleWishlistButtonClick,
          },
          cancel: onCancel
            ? {
                label: t('form.addToCartForm.buttons.cancel'),
                onClick: onCancel,
                type: 'button',
                variant: appConfig.form?.buttons.cancel?.defaultVariant,
              }
            : undefined,
          quantity: {
            decrease: {
              // disabled: formik.values.quantity === 1,
              label: '-',
              // onClick: () => {
              //   const previousQuantity = getFormikNumberValue(formik.values.quantity);
              //   if (previousQuantity) {
              //     formik.setFieldValue('quantity', previousQuantity - 1);
              //   }
              // },
            },
            increase: {
              // disabled: formik.values.quantity === appConfig.productMaxQuantity,
              label: '+',
              // onClick: () => {
              //   const previousQuantity = getFormikNumberValue(formik.values.quantity);
              //   if (previousQuantity) {
              //     formik.setFieldValue('quantity', previousQuantity + 1);
              //   }
              // },
            },
          },
          reset: {
            disabled: isResetButtonDisabled(),
            label: t('form.addToCartForm.buttons.reset'),
            type: 'reset',
            variant: appConfig.form?.buttons.cancel?.defaultVariant,
          },
          submit: {
            disabled: isSubmitButtonDisabled() || !isAtLeastOneSkuAvailable,
            label: isAtLeastOneSkuAvailable
              ? t('form.addToCartForm.buttons.submit')
              : t('form.addToCartForm.buttons.submitButNotAvailable'),
            type: 'submit',
            variant: appConfig.form?.buttons.submit?.defaultVariant,
          },
        },
        giftCard: product.giftCard
          ? {
              message: {
                formGroup: { label: t('form.addToCartForm.giftCard.message.label'), names: ['giftCard.message'] },
                textArea: { name: 'giftCard.message', rows: 3 },
              },
              receiverEmail: {
                formGroup: {
                  label: t('form.addToCartForm.giftCard.receiverEmail.label'),
                  names: ['giftCard.receiverEmail'],
                  required: true,
                },
                textInput: { name: 'giftCard.receiverEmail', registerOptions: { required: true }, type: 'email' },
              },
              receiverName: {
                formGroup: {
                  label: t('form.addToCartForm.giftCard.receiverName.label'),
                  names: ['giftCard.receiverName'],
                  required: true,
                },
                textInput: {
                  name: 'giftCard.receiverName',
                  registerOptions: { required: true },
                },
              },
              senderName: {
                formGroup: {
                  label: t('form.addToCartForm.giftCard.senderName.label'),
                  names: ['giftCard.senderName'],
                  required: true,
                },
                textInput: {
                  name: 'giftCard.senderName',
                  registerOptions: { required: true },
                },
              },
            }
          : undefined,
        productVariants: {
          formFields: productVariants.map(
            (productVariant) =>
              ({
                productVariant: productVariant,
                radio: {
                  id: `productvariant-${productVariant.id}`,
                  label: `${productVariant.name} (${productVariant.id})`,
                  name: 'productVariantId',
                  registerOptions: {
                    // onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    //   // handleProductVariantIdChange(e.target.value);
                    //   console.log('onChange', e.target.value);
                    // },
                    required: true,
                  },
                  value: productVariant.id,
                },
              } satisfies ArrayElement<FieldsHelper['productVariants']['formFields']>)
          ),
          formGroup: {
            label: t('form.addToCartForm.productVariants'),
            names: ['productVariantId'],
            omitForAttribute: true,
          },
        },

        quantity: {
          formGroup: { label: t('form.addToCartForm.quantity.label'), names: ['quantity'] },
          numberInput: {
            name: 'quantity',
            placeholder: t('form.addToCartForm.quantity.placeholder'),
            registerOptions: { max: appConfig.productMaxQuantity, min: 1, required: true, valueAsNumber: true },
          },
          select: {
            name: 'quantity',
            options: [...Array(appConfig.productMaxQuantity)].map(
              (quantity, index) =>
                ({
                  label: (index + 1).toString(),
                  value: (index + 1).toString(),
                } satisfies ArrayElement<FieldsHelper['quantity']['select']['options']>)
            ),
            placeholder: t('form.addToCartForm.quantity.placeholder'),
            registerOptions: { max: appConfig.productMaxQuantity, min: 1, required: true, valueAsNumber: true },
          },
        },
        skuAttributes: Object.entries(skuVariantAttributesMap).map(([attributeTypeCode, items]) => ({
          attribute: {
            title: items[0].attribute.title ?? '',
            typeCode: items[0].attribute.typeCode,
            value: skuAttributes[attributeTypeCode] !== '' ? skuAttributes[attributeTypeCode] : undefined,
          },
          formGroup: { label: items[0].attribute.title, names: [] },
          radios: items.map((item) => ({
            radio: {
              className: getSkuAttributeAvailability(item.attribute, skuAttributes),
              disabled:
                !appConfig.enableProductAvailabilityEmailNotification &&
                (getSkuAttributeAvailability(item.attribute, skuAttributes) === 'NOT_AVAILABLE' ||
                  getSkuAttributeAvailability(item.attribute, skuAttributes) === 'AVAILABLE_SOON'),
              id: `sku-attribute-${item.attribute.valueCode}`,
              label: item.attribute.value ?? '',
              name: `skuAttributes.${attributeTypeCode}`,
              value: item.attribute.valueCode ?? '',
            },
            skus: item.skus,
          })),
          // radios: {
          //   radios: attributes.map((attribute) => ({
          //     disabled:
          //       getSkuAttributeAvailability(attribute, skuAttributes) === 'NOT_AVAILABLE' ||
          //       getSkuAttributeAvailability(attribute, skuAttributes) === 'AVAILABLE_SOON',
          //     id: `sku-attribute-${attribute.value}`,
          //     label: attribute.value ?? '',
          //     name: `skuAttributes.${slugify(attributeName)}`,
          //     value: attribute.value ?? '',
          //   })),
          // },
          select: {
            includeEmptyOption: false,
            name: `skuAttributes.${attributeTypeCode}`,
            options: items.map((item) => ({
              className: getSkuAttributeAvailability(item.attribute, skuAttributes),
              disabled:
                !appConfig.enableProductAvailabilityEmailNotification &&
                (getSkuAttributeAvailability(item.attribute, skuAttributes) === 'NOT_AVAILABLE' ||
                  getSkuAttributeAvailability(item.attribute, skuAttributes) === 'AVAILABLE_SOON'),
              id: `sku-attribute-${item.attribute.valueCode}`,
              label:
                attributeTypeCode === 'Gift_card_valore' && item.attribute.value
                  ? formatCurrency(parseInt(item.attribute.value))
                  : item.attribute.value ?? '',
              value: item.attribute.valueCode ?? '',
            })),
            placeholder: t(`form.addToCartForm.skuAttributes.${attributeTypeCode}.placeholder` as ResourceKeys),
          },
        })),
        skus: {
          formFields: selectedProductVariant.skus
            ? selectedProductVariant.skus.map(
                (sku) =>
                  ({
                    option: {
                      className: sku.state,
                      disabled:
                        !appConfig.enableProductAvailabilityEmailNotification &&
                        (sku.state === 'NOT_AVAILABLE' || sku.state === 'AVAILABLE_SOON'),
                      label: sku.name,
                      value: sku.id,
                    },
                    radio: {
                      className: sku.state,
                      id: `sku-${sku.id}`,
                      label: `${sku.name} (${sku.id})`,
                      name: 'skuId',
                      registerOptions: {
                        disabled:
                          !appConfig.enableProductAvailabilityEmailNotification &&
                          (sku.state === 'NOT_AVAILABLE' || sku.state === 'AVAILABLE_SOON'),
                        required: true,
                      },
                      value: sku.id,
                    },
                    sku: sku,
                  } satisfies ArrayElement<FieldsHelper['skus']['formFields']>)
              )
            : [],
          formGroup: { label: t('form.addToCartForm.skus.label'), names: ['skuId'], omitForAttribute: true },
          select: {
            includeEmptyOption: false,
            name: 'skuId',
            placeholder: t('form.addToCartForm.skus.placeholder'),
          },
        },
      } satisfies FieldsHelper),
    [
      getSkuAttributeAvailability,
      handleWishlistButtonClick,
      inWishlist,
      isAtLeastOneSkuAvailable,
      onCancel,
      product.giftCard,
      productVariants,
      selectedProductVariant.skus,
      skuAttributes,
      skuVariantAttributesMap,
    ]
  );

  return { addToCartForm, fieldsHelper, formHelper, image, selectedProductVariant, selectedSku };
};
