import './MasterclassPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { BlockQuote } from '../BlockQuote';
import { Button } from '../Button';
import { Container } from '../Container';
import { MasterclassEventPageContentType, MasterclassPageContentType } from '../contentTypes';
import { MasterclassMailUpForm } from '../forms/MasterclassMailUpForm';
import { Icon } from '../Icon';
import { Section } from '../Section';
import { Slider } from '../Slider';
import { SliderButton } from '../SliderButton';
import { Page } from './Page';

export interface MasterclassPageProps {}

export const MasterclassPage = (props: MasterclassPageProps) => {
  const page = b2x.usePage<MasterclassPageContentType>();

  const [formSectionRef, scrollToFormSectionRef] = b2x.useScrollTo();

  const { showModal } = b2x.useModals();
  const [alreadyShow, setAleradyShow] = React.useState<boolean>(false);
  const [searchParams] = b2x.router.useSearchParams();
  const registration = searchParams.get('registration');

  React.useEffect(() => {
    if (alreadyShow === true) {
      if (registration) {
        showModal({
          children: <MasterclassModal result={registration} />,
          size: 'large',
          title: 'Registrazione',
        });
      }
    }
    setAleradyShow(true);
  }, [alreadyShow, registration, showModal]);

  return (
    <Page className="masterclass-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {page?.content?.body.mainSection && (
        <Section className="main mb-3 mb-lg-5 pb-3 pb-lg-5">
          {page.content.body.mainSection.video?.src?.xs?.video ? (
            <b2x.DeprecatedVideoFromContent autoplay controls {...page.content.body.mainSection.video} />
          ) : (
            <b2x.ImageFromContentV1 {...page.content.body.mainSection.image} fluid />
          )}
        </Section>
      )}
      {page?.content?.body.citation && (
        <Section className="citation mb-3 mb-lg-5 pb-3 pb-lg-5">
          <Container>
            <b2x.Row>
              {page.content.body.citation.title && (
                <b2x.Col className="d-flex align-items-center" size={{ lg: 6, xl: 5, xs: 12 }}>
                  <div className="px-3 px-lg-0">
                    <h3 className="d-flex flex-wrap flex-column text-uppercase">
                      {page.content.body.citation.title.lightBlack && (
                        <span className="fw-light h1 lh-1 m-0">
                          {b2x.formatHtml(page.content.body.citation.title.lightBlack)}
                        </span>
                      )}
                      {page.content.body.citation.title.semiboldBlack && (
                        <span className="fw-semi-bold display-1 lh-1">
                          {b2x.formatHtml(page.content.body.citation.title.semiboldBlack)}
                        </span>
                      )}
                      {page.content.body.citation.title.semiboldPrimary && (
                        <span className="fw-semi-bold display-1 lh-1 text-primary">
                          {b2x.formatHtml(page.content.body.citation.title.semiboldPrimary)}
                        </span>
                      )}
                    </h3>
                  </div>
                </b2x.Col>
              )}
              {page.content.body.citation.content && (
                <b2x.Col size={{ lg: 6, xl: 7, xs: 12 }}>
                  <BlockQuote size="h3">{b2x.formatHtml(page.content.body.citation.content)}</BlockQuote>
                </b2x.Col>
              )}
            </b2x.Row>
          </Container>
        </Section>
      )}
      {page?.content?.body.mosaic && (
        <Section className="mosaic position-relative mb-5 pb-3 pb-lg-5">
          {page.content.body.mosaic.mobileSlider && (
            <div className="d-block d-lg-none bg-white">
              <MosaicSlider slides={page.content.body.mosaic.mobileSlider} />
            </div>
          )}
          <Container>
            <b2x.Row>
              <b2x.Col size={{ lg: 6, xs: 12 }}>
                {page.content.body.mosaic.imageSquare && (
                  <div className="d-none d-lg-block mb-3">
                    <b2x.ImageFromContentV1 {...page.content.body.mosaic.imageSquare} fluid />
                  </div>
                )}
                {page.content.body.mosaic.content && (
                  <div className="px-lg-3 mt-3">
                    <div className="bg-white shadow ps-3 py-4 pe-4 pe-lg-5">
                      <BlockQuote size="h3">{b2x.formatHtml(page.content.body.mosaic.content)}</BlockQuote>
                    </div>
                  </div>
                )}
              </b2x.Col>
              <b2x.Col className="d-none d-lg-block" size={{ lg: 6, xs: 12 }}>
                {page.content.body.mosaic.imageBackground && (
                  <b2x.ImageAsBackgroundFromContentV1 {...page.content.body.mosaic.imageBackground} />
                )}
              </b2x.Col>
            </b2x.Row>
          </Container>
        </Section>
      )}
      {page?.content?.body.instructions && (
        <Section className="instructions mb-3 mb-lg-5 pb-3 pb-lg-5 text-center">
          <Container>
            {page.content.body.instructions.title && (
              <h3 className="h1 text-uppercase fw-semi-bold mb-5">{page.content.body.instructions.title}</h3>
            )}
            {page.content.body.instructions.list && (
              <div className="mb-5">
                <b2x.Row>
                  {page.content.body.instructions.list.map((instruction) => (
                    <b2x.Col key={instruction.contentSectionId} size={{ md: 3, xs: 6 }}>
                      <div className="mb-3 mb-md-0">
                        {instruction.image && (
                          <div className="mb-3">
                            <b2x.ImageFromContentV1 {...instruction.image} fluid />
                          </div>
                        )}
                        {instruction.content && <div className="">{b2x.formatHtml(instruction.content)}</div>}
                      </div>
                    </b2x.Col>
                  ))}
                </b2x.Row>
              </div>
            )}
            {page.content.body.enableForm && page.content.body.instructions.cta?.registration && (
              <div className="mb-2">
                <Button
                  label={page.content.body.instructions.cta.registration.label}
                  onClick={scrollToFormSectionRef}
                  variant={'primary'}
                />
              </div>
            )}
            {page.content.body.instructions.cta?.regulation && (
              <div>
                <b2x.DeprecatedCta className="btn btn-link" cta={page.content.body.instructions.cta.regulation}>
                  {page.content.body.instructions.cta.regulation.label}
                </b2x.DeprecatedCta>
              </div>
            )}
          </Container>
        </Section>
      )}
      <Section className="calendar mb-5 pb-lg-5">
        <Container>
          <div className="text-center mb-5">
            {page?.content?.body.calendar?.title && (
              <h3 className="h1 text-uppercase fw-semi-bold mb-0">
                {b2x.formatHtml(page.content.body.calendar.title)}
              </h3>
            )}
            {page?.content?.body.calendar?.subtitle && (
              <h3 className="text-primary fw-semi-bold">{b2x.formatHtml(page.content.body.calendar.subtitle)}</h3>
            )}
          </div>
        </Container>
        <MasterclassEventsList />
      </Section>
      {(page?.content?.body.coach?.title || page?.content?.body.coach?.list) && (
        <Section className="coach mb-3 mb-lg-5 text-center">
          <Container>
            {page.content.body.coach.title && (
              <h3 className="h1 text-uppercase fw-semi-bold mb-5">{b2x.formatHtml(page.content.body.coach.title)}</h3>
            )}
          </Container>
          {page.content.body.coach.list && <CoachSlider list={page.content.body.coach.list} />}
        </Section>
      )}
      {(page?.content?.body.staff?.title || page?.content?.body.staff?.list) && (
        <Section className="staff mb-3 mb-lg-5 text-center">
          <Container>
            {page.content.body.staff.title && (
              <h3 className="h1 text-uppercase fw-semi-bold mb-5">{b2x.formatHtml(page.content.body.staff.title)}</h3>
            )}
          </Container>
          {page.content.body.staff.list && <StaffSlider list={page.content.body.staff.list} />}
        </Section>
      )}
      {page?.content?.body.enableForm && (
        <Section background="lighter" className="form" innerRef={formSectionRef} withDots>
          <Container>
            {page.content.body.form?.title && (
              <h3 className="h1 text-uppercase fw-semi-bold mb-5 text-center">
                {b2x.formatHtml(page.content.body.form.title)}
              </h3>
            )}
            <MasterclassMailUpForm />
            <h5 className="text-center mt-5 vertical-align-middle">
              Per ulteriori informazioni, puoi contattarci via Whatsapp al numero{' '}
              <b2x.DeprecatedCta cta={{ to: { href: 'https://wa.me/+393314997240' } }}>
                <Icon className="align-text-bottom" name="sharing-whatapp" size={30} /> 331 4997240
              </b2x.DeprecatedCta>
            </h5>
          </Container>
        </Section>
      )}
    </Page>
  );
};

interface MosaicSliderProps {
  slides?: b2x.contentTypes.ContentSectionList<{
    image?: b2x.contentTypes.ImageContentSectionV1;
  }>;
}

const MosaicSlider = ({ slides }: MosaicSliderProps) => {
  return (
    <div className="mosaic-slider">
      <Slider
        loop
        responsive={{
          lg: {
            slidesPerView: 3.15,
          },
          md: {
            slidesPerView: 2.15,
          },
          xl: {
            slidesPerView: 4,
          },
        }}
        slidesPerView={1.15}
        spaceBetween={20}
      >
        {slides?.map((slide) => (
          <SwiperSlide key={slide.contentSectionId}>
            <b2x.ImageFromContentV1 {...slide.image} fluid />
          </SwiperSlide>
        ))}
      </Slider>
    </div>
  );
};

interface CoachSliderProps {
  list?: b2x.contentTypes.ContentSectionList<{
    content?: string;
    image?: b2x.contentTypes.ImageContentSectionV1;
    title?: string;
  }>;
}

const CoachSlider = ({ list }: CoachSliderProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);
  return (
    <div className="coach-slider position-relative px-0 px-lg-3">
      <Slider
        loop
        navigation
        responsive={{
          lg: {
            slidesPerView: 3.15,
          },
          md: {
            slidesPerView: 2.15,
          },
          xl: {
            slidesPerView: 4,
          },
        }}
        sliderNextEl={sliderNextButton}
        sliderPrevEl={sliderPrevButton}
        slidesPerView={1.15}
        spaceBetween={20}
      >
        {list?.map((slide) => (
          <SwiperSlide key={slide.contentSectionId}>
            {slide.image && (
              <div className="mb-3">
                <b2x.ImageFromContentV1 {...slide.image} fluid />
              </div>
            )}
            <div className="mb-3 px-3">
              {slide.title && <h6 className="fw-semi-bold">{b2x.formatHtml(slide.title)}</h6>}
              {slide.content && <span>{b2x.formatHtml(slide.content)}</span>}
            </div>
          </SwiperSlide>
        ))}
      </Slider>
      <SliderButton className="d-none d-lg-block" direction="left" innerRef={sliderPrevButton} size="large" />
      <SliderButton className="d-none d-lg-block" direction="right" innerRef={sliderNextButton} size="large" />
    </div>
  );
};

interface StaffSliderProps {
  list?: b2x.contentTypes.ContentSectionList<{
    content?: string;
    image?: b2x.contentTypes.ImageContentSectionV1;
    title?: string;
  }>;
}

const StaffSlider = ({ list }: StaffSliderProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);
  return (
    <div className="coach-slider position-relative px-0 px-lg-3">
      <Slider
        loop
        navigation
        responsive={{
          lg: {
            slidesPerView: 3.15,
          },
          md: {
            slidesPerView: 2.15,
          },
          xl: {
            slidesPerView: 4,
          },
        }}
        sliderNextEl={sliderNextButton}
        sliderPrevEl={sliderPrevButton}
        slidesPerView={1.15}
        spaceBetween={20}
      >
        {list?.map((slide) => (
          <SwiperSlide key={slide.contentSectionId}>
            {slide.image && (
              <div className="mb-3">
                <b2x.ImageFromContentV1 {...slide.image} fluid />
              </div>
            )}
            <div className="mb-3 px-3">
              {slide.title && <h6 className="fw-semi-bold">{b2x.formatHtml(slide.title)}</h6>}
              {slide.content && <span>{b2x.formatHtml(slide.content)}</span>}
            </div>
          </SwiperSlide>
        ))}
      </Slider>
      <SliderButton className="d-none d-lg-block" direction="left" innerRef={sliderPrevButton} size="large" />
      <SliderButton className="d-none d-lg-block" direction="right" innerRef={sliderNextButton} size="large" />
    </div>
  );
};

interface MasterclassEventListProps {}

const MasterclassEventsList = (props: MasterclassEventListProps) => {
  const page = b2x.usePageByCode<MasterclassPageContentType>('SITE_MASTERCLASS', {
    populate: {
      children: {
        content: true,
      },
      content: true,
    },
  });

  return (
    <div className="masterclass-events-list">
      {page?.children?.length && page.children.length > 0 && (
        <b2x.EqualHeight>
          <div className="d-none d-lg-flex flex-wrap px-2">
            {page.children.map((event, index) => (
              <div className="col-4 p-2" key={event.id}>
                <b2x.EqualHeightElement name="event-in-list">
                  {event.content &&
                    b2x.typedContent<MasterclassEventPageContentType>(event.content, (content) => (
                      <div
                        className={classnames(
                          'masterclass-event h-100 p-4',
                          { 'bg-lighter': page.children?.length && index + 1 < page.children.length },
                          { 'bg-primary': page.children?.length && index + 1 === page.children.length }
                        )}
                      >
                        {content.body.abstract?.date && (
                          <h3
                            className={classnames(
                              'fw-semi-bold mb-0',
                              { 'text-primary': page.children?.length && index + 1 < page.children.length },
                              { 'text-white': page.children?.length && index + 1 === page.children.length }
                            )}
                          >
                            {b2x.formatHtml(content.body.abstract.date)}
                          </h3>
                        )}
                        {content.body.abstract?.title && (
                          <h3 className="fw-semi-bold mb-3">{b2x.formatHtml(content.body.abstract.title)}</h3>
                        )}
                        {content.body.abstract?.content && <div>{b2x.formatHtml(content.body.abstract.content)}</div>}
                        {content.body.abstract?.enableCta && (
                          <b2x.DeprecatedCta
                            className={classnames(
                              'btn',
                              { 'btn-primary': page.children?.length && index + 1 < page.children.length },
                              { 'btn-lighter': page.children?.length && index + 1 === page.children.length }
                            )}
                            cta={{ to: { href: event.fullPath } }}
                          >
                            Scopri di più
                          </b2x.DeprecatedCta>
                        )}
                      </div>
                    ))}
                </b2x.EqualHeightElement>
              </div>
            ))}
          </div>
          <div className="d-block d-lg-none">
            <Slider
              responsive={{
                lg: {
                  slidesPerView: 3.15,
                },
                md: {
                  slidesPerView: 2.15,
                },
                xl: {
                  slidesPerView: 4,
                },
              }}
              slidesPerView={1.15}
              spaceBetween={20}
            >
              {page.children.map((event, index) => (
                <SwiperSlide key={event.id}>
                  <b2x.EqualHeightElement name="event-in-slider">
                    {event.content &&
                      b2x.typedContent<MasterclassEventPageContentType>(event.content, (content) => (
                        <div
                          className={classnames(
                            'masterclass-event h-100 p-4',
                            { 'bg-lighter': page.children?.length && index + 1 < page.children.length },
                            { 'bg-primary': page.children?.length && index + 1 === page.children.length }
                          )}
                        >
                          {content.body.abstract?.date && (
                            <h3
                              className={classnames(
                                'fw-semi-bold mb-0',
                                { 'text-primary': page.children?.length && index + 1 < page.children.length },
                                { 'text-white': page.children?.length && index + 1 === page.children.length }
                              )}
                            >
                              {b2x.formatHtml(content.body.abstract.date)}
                            </h3>
                          )}
                          {content.body.abstract?.title && (
                            <h3 className="fw-semi-bold mb-3">{b2x.formatHtml(content.body.abstract.title)}</h3>
                          )}
                          {content.body.abstract?.content && <div>{b2x.formatHtml(content.body.abstract.content)}</div>}
                          {content.body.abstract?.enableCta && (
                            <b2x.DeprecatedCta
                              className={classnames(
                                'btn',
                                { 'btn-primary': page.children?.length && index + 1 < page.children.length },
                                { 'btn-lighter': page.children?.length && index + 1 === page.children.length }
                              )}
                              cta={{ to: { href: event.fullPath } }}
                            >
                              Scopri di più
                            </b2x.DeprecatedCta>
                          )}
                        </div>
                      ))}
                  </b2x.EqualHeightElement>
                </SwiperSlide>
              ))}
            </Slider>
          </div>
        </b2x.EqualHeight>
      )}
    </div>
  );
};

interface MasterclassModalProps {
  result: string;
}

const MasterclassModal = ({ result }: MasterclassModalProps) => {
  return (
    <div>
      {result === 'success'
        ? 'Grazie per esserti registrato'
        : 'Qualcosa è andato storto durante la fase di registrazione.'}
    </div>
  );
};
