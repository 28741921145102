import { b2x } from '@b2x/react/src';
import React from 'react';

import { AuthUserSection } from '../auth/AuthUserSection';
import { Container } from '../Container';
import { PreSalePageContentType, RegistrationFormContentType, WhoisPageContentType } from '../contentTypes';
import { Countdown } from '../Countdown';
import { Page } from './Page';
import { SearchPage } from './SearchPage';

type UseCase = 'phase1Guest' | 'phase1Logged' | 'phase2Guest' | 'phase2Logged' | 'phase3Guest' | 'phase3Logged';

export interface PreSalePageProps {}

export const PreSalePage = (props: PreSalePageProps) => {
  const page = b2x.usePage<PreSalePageContentType>();
  const whoisContent = b2x.useContent<WhoisPageContentType>('WHOIS_PAGE_CONTENT');
  const registrationContent = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');

  const handleLoginSuccess = React.useCallback(() => {
    console.log();
  }, []);

  const handleRegistrationSuccess = React.useCallback(() => {
    console.log();
  }, []);

  const { session } = b2x.useAppContext();

  const goliveDateForLoggedUsers = React.useMemo(
    () => b2x.parseDateTimeFromContent(page?.content?.body.goliveDateForLoggedUsers),
    [page?.content?.body.goliveDateForLoggedUsers]
  );

  const countdownForLoggedUsers = b2x.useCountdown(goliveDateForLoggedUsers);

  const goliveDateForAllUsers = React.useMemo(
    () => b2x.parseDateTimeFromContent(page?.content?.body.goliveDateForAllUsers),
    [page?.content?.body.goliveDateForAllUsers]
  );

  const countdownForAllUsers = b2x.useCountdown(goliveDateForAllUsers);

  const useCase = React.useMemo<UseCase | undefined>(() => {
    if (
      countdownForLoggedUsers?.completed !== undefined &&
      countdownForAllUsers?.completed !== undefined &&
      session?.userLogged !== undefined
    ) {
      if (!countdownForLoggedUsers.completed) {
        if (session.userLogged) {
          return 'phase1Logged';
        } else {
          return 'phase1Guest';
        }
      } else if (!countdownForAllUsers.completed) {
        if (session.userLogged) {
          return 'phase2Logged';
        } else {
          return 'phase2Guest';
        }
      } else {
        if (session.userLogged) {
          return 'phase3Logged';
        } else {
          return 'phase3Guest';
        }
      }
    } else {
      return undefined;
    }
  }, [countdownForAllUsers?.completed, countdownForLoggedUsers?.completed, session?.userLogged]);

  return (
    <>
      {useCase === 'phase2Logged' || useCase === 'phase3Guest' || useCase === 'phase3Logged' ? (
        <SearchPage />
      ) : (
        <Page noPaddingBottom thingsToLoadBeforePageReady={[page, useCase]}>
          {useCase && (
            <>
              <Container>
                <h2 className="text-center mb-3 mb-md-4">
                  {page?.content?.body.title && page.content.body.title[useCase]}
                </h2>
                <b2x.Row className="justify-content-center">
                  <b2x.Col size={{ lg: 10, md: 10, sm: 11 }}>
                    <b2x.Div marginX={{ sm: 0, xs: -3 }}>
                      <b2x.ImageFromContentV1 {...page?.content?.body.image} className="d-block m-auto" fluid />
                    </b2x.Div>
                  </b2x.Col>
                </b2x.Row>
                {countdownForLoggedUsers && (
                  <b2x.Row className="justify-content-center">
                    <b2x.Col size={{ lg: 7, md: 9, sm: 10, xl: 6, xs: 11, xxl: 5 }}>
                      <b2x.Div className="position-relative" marginTop={{ md: -5, xs: -4 }}>
                        <Countdown
                          classNames="mb-4"
                          days={countdownForLoggedUsers.days}
                          hours={countdownForLoggedUsers.hours}
                          minutes={countdownForLoggedUsers.minutes}
                          seconds={countdownForLoggedUsers.seconds}
                        />
                        <b2x.Div className="h5 fw-light" marginBottom={4} textAlign={'center'}>
                          {page?.content?.body.copy && b2x.formatHtml(page.content.body.copy[useCase])}
                        </b2x.Div>
                      </b2x.Div>
                    </b2x.Col>
                  </b2x.Row>
                )}
              </Container>
              {!session?.userLogged && (
                <div className="bg-lighter">
                  <Container>
                    <b2x.Div className="text-primary h3" paddingY={{ md: 5, xs: 4 }} textAlign="center">
                      {page?.content?.body.hashtag}
                    </b2x.Div>
                  </Container>
                  <AuthUserSection
                    classNames="pb-5"
                    login={{
                      handleLoginSuccess,
                      title: whoisContent?.body.continueAsRegisteredUser?.title,
                    }}
                    signUp={{
                      handleRegistrationSuccess,
                      newsletterDiscount: registrationContent?.body.newsletterDiscount,
                      ...whoisContent?.body.continueAsGuest,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </Page>
      )}
    </>
  );
};
