import './SliderD.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { SliderDContentSection } from '../contentTypes';
import { Icon } from '../Icon';
import { Slider } from '../Slider';
import { SliderButton } from '../SliderButton';
export interface SliderDProps extends SliderDContentSection {}

export const SliderD = React.memo(({ sliderList, subTitle, title }: SliderDProps) => {
  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);

  return (
    <div className={`${sliderUUID} SliderD`}>
      <div className="row">
        <div className="col-12 p-0 p-xl-3">
          <h3 className="text-center text-uppercase h1 mb-1 fw-semi-bold">{title}</h3>
          <h5 className="text-center fw-light mb-5">{subTitle}</h5>
          <div className="slider-container">
            <Slider
              loop
              navigation
              responsive={{
                lg: {
                  slidesPerView: 3.15,
                },
                md: {
                  slidesPerView: 2.15,
                },
                xl: {
                  slidesPerView: 4,
                },
              }}
              sliderNextEl={`.${sliderUUID} .swiper-button-next`}
              sliderPrevEl={`.${sliderUUID} .swiper-button-prev`}
              slidesPerView={1.15}
              spaceBetween={20}
            >
              {sliderList?.map(
                (slide, index) =>
                  slide.cta && (
                    <SwiperSlide key={slide.contentSectionId}>
                      <div className="slide-image">
                        <b2x.DeprecatedCta cta={slide.cta}>
                          <b2x.ImageFromContentV1 {...slide.img} />
                        </b2x.DeprecatedCta>
                      </div>
                      <div className="slide-content-wrapper">
                        <div className="slide-content text-center fw-semi-bold">
                          <div className="slide-text mt-3">
                            <p className="m-0">{slide.title}</p>
                          </div>
                          <div className="slide-action mt-2 text-uppercase">
                            <b2x.DeprecatedCta className="text-decoration-none" cta={slide.cta}>
                              {slide.cta.label} <Icon name="small-arrow-right" size={16} />
                            </b2x.DeprecatedCta>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
              )}
            </Slider>
            <SliderButton direction="left" size="large" />
            <SliderButton direction="right" size="large" />
          </div>
        </div>
      </div>
    </div>
  );
});
SliderD.displayName = 'SliderD';
