import { b2x } from '@b2x/react/src';

import { AppContext } from './AppContext';
import { Footer } from './Footer';
import { Header } from './Header';
import { Icomoon } from './Icomoon';
import { ModalsAndOffcanvasses } from './ModalsAndOffcanvasses';
import { AccountHomePage } from './pages/AccountHomePage';
import { AccountPage } from './pages/AccountPage';
import { BuilderV1Page } from './pages/BuilderV1Page';
import { CartPage } from './pages/CartPage';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { CollectionPage } from './pages/CollectionPage';
import { CollectionsPage } from './pages/CollectionsPage';
import { ContestPage } from './pages/ContestPage';
import { CrewAmbassadorPage } from './pages/CrewAmbassadorPage';
import { CrewAmbassadorsPage } from './pages/CrewAmbassadorsPage';
import { CrewBrandPage } from './pages/CrewBrandPage';
import { CrewEventPage } from './pages/CrewEventPage';
import { CrewEventsPage } from './pages/CrewEventsPage';
import { Error404Page } from './pages/Error404Page';
import { Error500Page } from './pages/Error500Page';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { GiftCardPage } from './pages/GiftCardPage';
import { HomePage } from './pages/HomePage';
import { LandingTemplateAPage } from './pages/LandingTemplateAPage';
import { LandingTemplateBPage } from './pages/LandingTemplateBPage';
import { LookPage } from './pages/LookPage';
import { MasterclassEventPage } from './pages/MasterclassEventPage';
import { MasterclassPage } from './pages/MasterclassPage';
import { MockPage } from './pages/MockPage';
import { PreSalePage } from './pages/PreSalePage';
import { ProductPage } from './pages/ProductPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { SearchPage } from './pages/SearchPage';
import { ServicePage } from './pages/ServicePage';
import { TemplatePage } from './pages/TemplatePage';
import { TestPage } from './pages/TestPage';
import { ThankYouPage } from './pages/ThankYouPage';
import { ThankYouPageOrderFailed } from './pages/ThankYouPageOrderFailed';
import { UnsubscribeNewsletterPage } from './pages/UnsubscribeNewsletterPage';
import { WhoisPage } from './pages/WhoisPage';

export const App = () => {
  return (
    <AppContext>
      {(appContext, appStaticContext) => (
        <b2x.App
          onAddToCartSuccess={appStaticContext.showCartOffcanvas}
          onAddToWishlistAsGuestButtonClick={appStaticContext.showAccountOffcanvas}
        >
          <ModalsAndOffcanvasses />
          <Icomoon />
          <div className="d-flex flex-column flex-grow-1">
            <Header />
            <div className="flex-grow-1">
              <b2x.router.MainRouter
                codeMappings={{
                  SITEMAP: <HomePage />,
                  SITE_ACCOUNT: <AccountHomePage />,
                  SITE_CCN_AREA: { component: <AccountPage />, separateRouter: true },
                  SITE_CRW_AMBASSADORS: <CrewAmbassadorsPage />,
                  SITE_CRW_BRAND: <CrewBrandPage />,
                  SITE_CRW_EVENTS: <CrewEventsPage />,
                  SITE_CUSTOMER_CARE: { component: <ServicePage />, separateRouter: true },
                  SITE_LEGAL_AREA: { component: <ServicePage />, separateRouter: true },
                  SITE_MASTERCLASS: <MasterclassPage />,
                  SITE_TEMPLATE: <TemplatePage />,
                }}
                error404Page={<Error404Page />}
                fixedMappings={{
                  cart: <CartPage />,
                  'change-password': <ChangePasswordPage />,
                  checkout: <CheckoutPage />,
                  error: <Error500Page />,
                  'forgot-password': <ForgotPasswordPage />,
                  'order-ko': <ThankYouPageOrderFailed />,
                  'order-ok': <ThankYouPage />,
                  register: <RegistrationPage />,
                  search: <SearchPage />,
                  test: <TestPage />,
                  'unsubscribe-newsletter': <UnsubscribeNewsletterPage />,
                  whois: <WhoisPage />,
                }}
                templateMappings={{
                  COLLECTIONS_PAGE_CONTENT_TYPE: <CollectionsPage />,
                  COLLECTION_PAGE_CONTENT_TYPE: <CollectionPage />,
                  CONTEST_PAGE_CONTENT_TYPE: <ContestPage />,
                  CREW_AMBASSADOR_PAGE_CONTENT_TYPE: <CrewAmbassadorPage />,
                  CREW_EVENT_PAGE_CONTENT_TYPE: <CrewEventPage />,
                  LANDING_TEMPLATE_A_PAGE_CONTENT_TYPE: <LandingTemplateAPage />,
                  LANDING_TEMPLATE_B_PAGE_CONTENT_TYPE: <LandingTemplateBPage />,
                  LOOK_PAGE_CONTENT_TYPE: <LookPage />,
                  MASTERCLASS_EVENT_PAGE_CONTENT_TYPE: <MasterclassEventPage />,
                  MOCK_PAGE_CONTENT_TYPE: <MockPage />,
                  PAGE_BUILDER_V1_CONTENT_TYPE: <BuilderV1Page />,
                  PRE_SALE_PAGE_CONTENT_TYPE: <PreSalePage />,
                }}
                typeMappings={{
                  CATALOG: <SearchPage />,
                  LISTING: <SearchPage />,
                  PRODUCT: <b2x.ProductTypeSwitch default={<ProductPage />} giftCard={<GiftCardPage />} />,
                }}
              />
            </div>
            <Footer />
            <div id="products-tiles-container"></div>
          </div>
          <b2x.FloatingActionButtonContainer direction="column" position="bottom start">
            <b2x.WhatsAppClickToChatFABButton />
            <b2x.CookieFABButton />
          </b2x.FloatingActionButtonContainer>
        </b2x.App>
      )}
    </AppContext>
  );
};
