import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { GenericContentType, HeaderContentType, HeaderMenuItemContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import logo from './images/logo.svg';
import logoCompact from './images/logo-small.svg';
import { TopSlider } from './slider/TopSlider';

const fadeDuration = 200;

export interface DesktopHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  recalculateHeaderHeight(): void;
  secondRowRef: React.RefObject<HTMLDivElement>;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({ content, recalculateHeaderHeight, secondRowRef, visibleFrom }: DesktopHeaderProps) => {
  const { session, shippingCountry } = b2x.useAppContext();
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();
  const { headerCheckout } = useAppContext();

  const menu = b2x.useMenu('MENU_HEADER', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, []);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return !headerCheckout ? (
    <>
      <TopBar content={content} visibleFrom={visibleFrom} />
      <FirstRow
        activeCategory={activeCategory}
        localeBoxVisible={localeBoxVisible}
        recalculateHeaderHeight={recalculateHeaderHeight}
        searchBoxVisible={searchBoxVisible}
        toggleLocaleBoxVisible={toggleLocaleBoxVisible}
        toggleSearchBoxVisible={toggleSearchBoxVisible}
        visibleFrom={visibleFrom}
      />
      <SecondRow
        activeCategory={activeCategory}
        innerRef={secondRowRef}
        localeBoxVisible={localeBoxVisible}
        menu={menu}
        recalculateHeaderHeight={recalculateHeaderHeight}
        searchBoxVisible={searchBoxVisible}
        setActiveCategory={setActiveCategory}
        toggleLocaleBoxVisible={toggleLocaleBoxVisible}
        toggleSearchBoxVisible={toggleSearchBoxVisible}
        visibleFrom={visibleFrom}
      />
    </>
  ) : (
    <div
      className={classnames(
        'HeaderCheckoutDesktop sticky-top',
        `d-none d-${visibleFrom}-block`,
        'py-3 shadow bg-white'
      )}
    >
      <div className="container-xxl">
        <div className="d-flex justify-content-between align-items-center">
          <div className="logo" style={{ flex: '1 1 0' }}>
            <Logo compact recalculateHeaderHeight={recalculateHeaderHeight} />
          </div>
          <div className="text-center h5 m-0" style={{ flex: '1 1 0' }}>
            {session?.cart?.itemsNumber && (
              <b2x.Fetching item={session.cart.itemsNumber}>
                {(count) => t('header.cartSummary', { count: count })}
              </b2x.Fetching>
            )}
          </div>
          <div className="text-end extra-small fw-light text-dark text-uppercase" style={{ flex: '1 1 0' }}>
            {session?.cart?.shippingProfile?.freeThreshold &&
              t('header.freeShippingFrom', {
                from: b2x.formatCurrency(session.cart.shippingProfile.freeThreshold),
                locale: shippingCountry?.name,
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

interface FirstRowProps {
  activeCategory?: b2x.MenuApiDto;
  localeBoxVisible: boolean;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  toggleLocaleBoxVisible(): void;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const FirstRow = ({
  activeCategory,
  localeBoxVisible,
  recalculateHeaderHeight,
  searchBoxVisible,
  toggleLocaleBoxVisible,
  toggleSearchBoxVisible,
  visibleFrom,
}: FirstRowProps) => {
  const { isHeaderFirstRowVisible, transparentHeader } = useAppContext();
  const { setHeaderFirstRowRef } = useAppStaticContext();

  return (
    <div
      className={classnames('header-first-row', `d-none d-${visibleFrom}-block`, {
        transparent: transparentHeader && activeCategory === undefined && !localeBoxVisible && !searchBoxVisible,
      })}
      ref={setHeaderFirstRowRef}
    >
      <Container>
        <b2x.Row className="align-items-center py-3">
          <b2x.Col className="d-flex">
            <LocaleToggle toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          </b2x.Col>
          <b2x.Col className="d-flex flex-grow-0">
            <Logo compact recalculateHeaderHeight={recalculateHeaderHeight} />
          </b2x.Col>
          <b2x.Col className="d-flex justify-content-end align-items-center">
            <b2x.Fade delayIn={fadeDuration / 2} duration={fadeDuration} show={isHeaderFirstRowVisible}>
              <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
            </b2x.Fade>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};

interface SecondRowProps {
  activeCategory?: b2x.MenuApiDto;
  innerRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  menu?: b2x.MenuApiDto;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  toggleLocaleBoxVisible(): void;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const SecondRow = ({
  activeCategory,
  innerRef,
  localeBoxVisible,
  menu,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  toggleLocaleBoxVisible,
  toggleSearchBoxVisible,
  visibleFrom,
}: SecondRowProps) => {
  const { isHeaderFirstRowVisible, transparentHeader } = useAppContext();
  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames('header-second-row-wrapper sticky-top', `d-none d-${visibleFrom}-block`, {
        sticky: !isHeaderFirstRowVisible,
      })}
      ref={innerRef}
    >
      <div
        className={classnames(
          'header-second-row',
          { shadow: !searchBoxVisible && !localeBoxVisible },
          {
            transparent:
              transparentHeader &&
              isHeaderFirstRowVisible &&
              activeCategory === undefined &&
              !localeBoxVisible &&
              !searchBoxVisible,
          }
        )}
      >
        <Container>
          <b2x.Row className="align-items-stretch">
            <b2x.Col className="d-flex py-2">
              <b2x.Fade duration={fadeDuration} show={!isHeaderFirstRowVisible}>
                <Logo recalculateHeaderHeight={recalculateHeaderHeight} />
              </b2x.Fade>
            </b2x.Col>
            <b2x.Col className="d-flex flex-grow-0">
              <Menu
                activeCategory={activeCategory}
                menu={menu}
                setActiveCategory={setActiveCategory}
                subMenuDropdownRef={subMenuDropdownRef}
              />
            </b2x.Col>
            <b2x.Col className="d-flex justify-content-end align-items-center">
              <b2x.Fade duration={fadeDuration} show={!isHeaderFirstRowVisible}>
                <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
              </b2x.Fade>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
      {activeCategory && (
        <SubMenuDropdown
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subMenuDropdownRef={subMenuDropdownRef}
        />
      )}
      {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
      {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />}
    </div>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  visibleFrom: b2x.Breakpoint;
}

const TopBar = ({ content, visibleFrom }: TopBarProps) => {
  const topBarRef = React.useRef<HTMLDivElement>(null);
  const { setTopBarDesktopHeight } = useAppStaticContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRef]);

  return (
    <div
      className={`d-none d-${visibleFrom}-block extra-small fw-light`}
      ref={topBarRef}
      style={{
        background: content?.body.backgroundColor ? content.body.backgroundColor : '#F6F6F6',
        color: content?.body.textColor ? content.body.textColor : '#000000',
      }}
    >
      <b2x.Container>
        <b2x.Row>
          <b2x.Col className="text-start pt-1" size={3}>
            {content?.body.leftTopHeader && b2x.formatHtml(content.body.leftTopHeader)}
          </b2x.Col>
          <b2x.Col className="text-center" size={6}>
            {content && content.body.messagesList ? (
              <TopSlider {...content.body} />
            ) : (
              <span className="text-uppercase">{t('welcome')}</span>
            )}
          </b2x.Col>
          <b2x.Col className="text-end pt-1" size={3}>
            {content?.body.rightTopHeader && b2x.formatHtml(content.body.rightTopHeader)}
          </b2x.Col>
        </b2x.Row>
      </b2x.Container>
    </div>
  );
};

interface LocaleToggleProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleToggle = ({ toggleLocaleBoxVisible }: LocaleToggleProps) => {
  const { locale, shippingCountry } = b2x.useAppContext();

  return (
    <Button
      alignItems="center"
      className="text-decoration-none"
      iconStart={{ name: 'traslate', size: 25 }}
      label={
        locale &&
        shippingCountry && (
          <span>{t('header.sendTo', { country: shippingCountry.name, locale: locale.languageDescriptionLocal })}</span>
        )
      }
      onClick={toggleLocaleBoxVisible}
      size="small"
      variant="blank"
    />
  );
};

interface TogglesProps {
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      <div className="hstack gap-2">
        <Button iconEnd={{ name: 'search', size: 25 }} onClick={toggleSearchBoxVisible} variant="blank" />
        {session?.customer ? (
          <b2x.router.Link className="lh-1" to={getPagePath('SITE_ACCOUNT')}>
            <Icon name="profile-full" size={25} />
          </b2x.router.Link>
        ) : (
          <Button iconEnd={{ name: 'profile', size: 25 }} onClick={showAccountOffcanvas} variant="blank" />
        )}

        {session?.customer ? (
          <b2x.router.Link className="lh-1" to="/account/area/wishlist">
            <Button className="position-relative" variant="blank">
              <Icon name={'wishlist'} size={25} />
              {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                  {(session.wishlist.products ? session.wishlist.products.length : 0) +
                    (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                </span>
              )}
            </Button>
          </b2x.router.Link>
        ) : (
          <Button iconEnd={{ name: 'wishlist', size: 25 }} onClick={showAccountOffcanvas} variant="blank" />
        )}

        <Button className="position-relative" onClick={showCartOffcanvas} variant="blank">
          <Icon name={'cart'} size={25} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </>
  );
};

interface LogoProps {
  compact?: boolean;
  recalculateHeaderHeight(): void;
}

const Logo = ({ compact, recalculateHeaderHeight }: LogoProps) => {
  return (
    <b2x.router.Link to="/">
      <b2x.Image className="logo" onLoad={recalculateHeaderHeight} src={compact ? logoCompact : logo} />
    </b2x.router.Link>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item mx-3 d-flex align-items-center', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <Button
        className={classnames('text-decoration-none fw-semi-bold p-0 text-nowrap text-uppercase', {
          active: activeCategory && activeCategory.id === firstLevelCategory.id,
        })}
        label={firstLevelCategory.label}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}
        size="small"
        variant="blank"
      />
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({ activeCategory, menu, setActiveCategory, subMenuDropdownRef }: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
    }
  }, [idActiveCategory, setActiveCategory]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 100);

  return (
    <div className="menu d-flex">
      {menu?.children.map((firstLevelCategory) => {
        const color = firstLevelCategory.tags?.find((tag) => tag.startsWith('color-'))?.substring(6);
        return (
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              <b2x.router.Link
                className={classnames(
                  'Button btn btn-link blank btn-sm text-decoration-none text-primary fw-semi-bold p-0 text-nowrap hstack text-uppercase',
                  {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  },
                  { [`text-${color}`]: color !== undefined }
                )}
                to={firstLevelCategory.link}
              >
                {firstLevelCategory.iconName && isIconName(firstLevelCategory.iconName) && (
                  <Icon name={firstLevelCategory.iconName} size={20} />
                )}
                <span>{firstLevelCategory.label}</span>
              </b2x.router.Link>
            </MenuItemContainer>
          ))
        );
      })}
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <div
      className={classnames({ mounted: isMounted }, 'sub-menu-dropdown shadow-sm w-100 py-5')}
      ref={subMenuDropdownRef}
    >
      <Container>
        <b2x.Row cols={4}>
          {/* {activeCategory.id} */}
          {activeCategory.children.map((secondLevelCategory) => (
            <b2x.Col className="d-flex flex-column" key={secondLevelCategory.id}>
              {secondLevelCategory.content &&
                b2x.typedContent<HeaderMenuItemContentType>(secondLevelCategory.content, (content) => (
                  <>
                    <div className="mb-4">
                      <b2x.ImageFromContentV1 fluid {...content.body.img} />
                    </div>
                    <div className="fw-bold text-uppercase text-center">
                      {content.body.cta && (
                        <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.cta}>
                          {content.body.titleA}
                          <span className="text-primary"> {content.body.titleB}</span>
                        </b2x.DeprecatedCta>
                      )}
                    </div>
                  </>
                ))}
              {secondLevelCategory.children.map((thirdLevelCategory) => (
                <div key={thirdLevelCategory.id}>
                  {thirdLevelCategory.children.length > 0 ? (
                    <ul className="list-unstyled">
                      <div className="border-bottom mb-3">
                        <h6 className="small text-uppercase text-dark">
                          <b2x.router.Link
                            className="text-reset text-decoration-none"
                            onClick={close}
                            to={thirdLevelCategory.link}
                          >
                            {thirdLevelCategory.label}
                          </b2x.router.Link>
                        </h6>
                      </div>
                      {thirdLevelCategory.children.map(
                        (fourLevelCategory) =>
                          fourLevelCategory.link && (
                            <li key={fourLevelCategory.id}>
                              <b2x.router.Link
                                className="text-reset text-decoration-none"
                                onClick={close}
                                to={fourLevelCategory.link}
                              >
                                <span className="fw-light small">{fourLevelCategory.label}</span>
                              </b2x.router.Link>
                            </li>
                          )
                      )}
                    </ul>
                  ) : (
                    <h6 className="small text-uppercase fw-bold">
                      <b2x.router.Link
                        className="text-reset text-decoration-none"
                        onClick={close}
                        to={thirdLevelCategory.link}
                      >
                        {thirdLevelCategory.label}
                      </b2x.router.Link>
                    </h6>
                  )}
                </div>
              ))}
            </b2x.Col>
          ))}
        </b2x.Row>
      </Container>
    </div>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleSearchBoxVisible}>
      <div className="row justify-content-center">
        <div className="col-8">
          <b2x.SimpleSearchForm onSuccess={toggleSearchBoxVisible} />
        </div>
      </div>
    </Box>
  );
};

interface LocaleBoxProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
  const content = b2x.useContent<GenericContentType>('GENERIC_CONTENT');

  return (
    <Box onCloseButtonClick={toggleLocaleBoxVisible}>
      <div className="row justify-content-center text-center">
        <div className="col-5">
          <b2x.LocaleForm />
          {content?.body.alertChangeCountry && (
            <div className="small pt-3">{b2x.formatHtml(content.body.alertChangeCountry)}</div>
          )}
        </div>
      </div>
    </Box>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  onCloseButtonClick(): void;
}

const Box = ({ children, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className="box py-5 shadow" ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button iconEnd={{ name: 'delete', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
        {children}
      </Container>
    </div>
  );
};
