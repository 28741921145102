import './CollectionsPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';
import { CollectionPageContentType } from '../contentTypes';
import { PageHeader } from '../PageHeader';
import { SliderF } from '../slider/SliderF';
import { Page } from './Page';

export interface CollectionPageProps {}

export const CollectionPage = (props: CollectionPageProps) => {
  const page = b2x.usePage<CollectionPageContentType>({
    populate: {
      children: true,
    },
  });

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="CollectionPage">
        {page?.content?.body.pageTitle && <PageHeader breadcrumb small title={page.content.body.pageTitle} />}
        {(page?.content?.body.mainImage || page?.content?.body.mainVideo) && (
          <div className="sectionMain mb-5">
            {page.content.body.mainVideo ? (
              <div className="mainVideo">
                <b2x.DeprecatedVideoFromContent autoplay controls src={page.content.body.mainVideo.src} />
              </div>
            ) : (
              <div className="mainImage fit-image">
                <b2x.ImageFromContentV1 {...page.content.body.mainImage} fluid />
              </div>
            )}
          </div>
        )}
        <Container>
          {page?.content?.body.lookRowList?.map((lookRow) => (
            <b2x.Row className="justify-content-center align-items-center" key={lookRow.contentSectionId}>
              {lookRow.columnList?.map((column) => {
                const directory = page.children?.find((child) => child.code === column.lookDirectoryCode);
                return (
                  <b2x.Col
                    className={classnames(
                      column.columnSize === '6'
                        ? `col-10 col-md-${column.columnSize}`
                        : `col-10 col-md-${column.columnSize}`,
                      'mb-5',
                      column.contentToShow === 'image' ? 'imageColumn' : 'textColumn'
                    )}
                    key={column.contentSectionId}
                  >
                    {column.contentToShow === 'image' ? (
                      <div className={'fit-image'}>
                        <SliderF sliderList={column.imageList} />
                        {directory && (
                          <div className="text-end mt-3 mt-md-4">
                            <b2x.router.Link
                              className="text-black text-decoration-underline fw-light h3"
                              to={directory.fullPath}
                            >
                              {column.ctaLabel
                                ? column.ctaLabel
                                : directory.name
                                    .split(' ')
                                    .map((string, index) => (
                                      <>{index > 0 ? <span className="h1 fw-light"> /{string}</span> : string}</>
                                    ))}
                            </b2x.router.Link>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className={classnames(column.extraPadding && 'px-0 px-md-5')}>
                          {column.title && (
                            <h3 className="h1 text-uppercase lh-1 mb-3 mb-md-4">{b2x.formatHtml(column.title)}</h3>
                          )}
                          {column.description && (
                            <div className="h3 fw-light">{b2x.formatHtml(column.description)}</div>
                          )}
                        </div>
                      </div>
                    )}
                  </b2x.Col>
                );
              })}
            </b2x.Row>
          ))}
        </Container>
      </div>
    </Page>
  );
};
