import './SliderB.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { SliderBContentSection } from '../contentTypes';
import { Slider } from '../Slider';
import { SliderButton } from '../SliderButton';
export interface SliderBProps extends SliderBContentSection {
  contentClassName?: string;
  titleAClassName?: string;
}

export const SliderB = ({
  content,
  contentClassName,
  sliderList,
  titleA,
  titleAClassName,
  titleB,
  titleC,
}: SliderBProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);
  return (
    <div className="SliderB">
      <div className="row">
        <div className="col-lg-4 col-xxl-3 d-flex align-items-center px-lg-5 p-3">
          <div className="befor-collection-slider">
            <h3 className="h1 text-uppercase mb-lg-4 mb-3">
              <span className={classNames('d-block fw-light', titleAClassName)}>{b2x.formatHtml(titleA)}</span>
              <span className="d-block fw-semi-bold">{titleB}</span>
              <span className="d-block fw-semi-bold text-primary">{titleC}</span>
            </h3>
            <p className={classNames('h3 fw-light', contentClassName)}>{b2x.formatHtml(content)}</p>
          </div>
        </div>
        <div className="col-lg-8 col-xxl-9 p-0 pe-lg-0 ps-lg-3">
          <div className="slider-container">
            <Slider
              className="absolute-content next-slide-2"
              loop
              navigation
              responsive={{
                md: {
                  slidesPerView: 2.15,
                },
                xl: {
                  slidesPerView: 2.5,
                },
              }}
              sliderNextEl={sliderNextButton}
              sliderPrevEl={sliderPrevButton}
              slidesPerView={1.15}
              spaceBetween={20}
            >
              {sliderList?.map(
                (slide, index) =>
                  slide.cta && (
                    <SwiperSlide key={slide.contentSectionId}>
                      <b2x.DeprecatedCta cta={slide.cta}>
                        <div className="slide-image">
                          <b2x.ImageFromContentV1 {...slide.img} />
                        </div>
                        <div className="slide-content-wrapper d-flex align-items-end justify-content-center text-center pb-3">
                          <div className="slide-content">
                            <div className={`slide-text text-${slide.cta.variant}`}>
                              <h4>{slide.cta.label}</h4>
                            </div>
                          </div>
                        </div>
                      </b2x.DeprecatedCta>
                    </SwiperSlide>
                  )
              )}
            </Slider>
            <SliderButton direction="left" innerRef={sliderPrevButton} size="large" />
            <SliderButton direction="right" innerRef={sliderNextButton} size="large" />
          </div>
        </div>
      </div>
    </div>
  );
};
