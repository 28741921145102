import './CrewAmbassadorPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { AmbassadorList } from '../AmbassadorList';
import { Container } from '../Container';
import { CrewAmbassadorPageContentType } from '../contentTypes';
import { Page } from './Page';

export interface CrewAmbassadorPageProps {}

export const CrewAmbassadorPage = (props: CrewAmbassadorPageProps) => {
  const page = b2x.usePage<CrewAmbassadorPageContentType>();
  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="CrewAmbassadorPage">
        <div className="wallpaper">
          <b2x.ImageAsBackgroundFromContentV1 {...page?.content?.body.wallpaperImage} />
        </div>
        <Container>
          <div className="profile-main-container bg-white shadow mb-4 p-3 p-md-5">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-md-4">
                <div className="profile-image text-center mb-3 mb-md-0">
                  <b2x.ImageFromContentV1
                    className="border border-5 border-white rounded-circle"
                    {...page?.content?.body.profileImage}
                    fluid
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 ps-md-5 d-flex align-items-center">
                <div>
                  {page?.content?.body.name && (
                    <h3 className="profile-name h1 fw-bold mb-4">{page.content.body.name}</h3>
                  )}
                  {page?.content?.body.citation && (
                    <div className="mb-4">&quot;{b2x.formatHtml(page.content.body.citation)}&quot;</div>
                  )}
                  {page?.content?.body.memberSince && (
                    <table className="table mb-1" style={{ tableLayout: 'fixed' }}>
                      <tbody>
                        <tr>
                          <th>Con Equestro da</th>
                          <td>{page.content.body.memberSince}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="profile-description mb-5">
            {page?.content?.body.description && b2x.formatHtml(page.content.body.description)}
          </div>
          {page?.content?.body.valueList && (
            <b2x.Row className="ambassador-value-list">
              {page.content.body.valueList.map((value, index) => (
                <b2x.Col
                  className={classnames('d-flex flex-wrap mb-4 col-12', { 'col-xl-6': index > 0 })}
                  key={value.contentSectionId}
                >
                  <div className="value-image bg-lighter col-12 col-md-6">
                    <b2x.ImageFromContentV1 className="d-md-none" {...value.image} fluid />
                    <b2x.ImageAsBackgroundFromContentV1 className="d-none d-md-block" {...value.image} />
                  </div>
                  <div className="value-content position-relative bg-lighter col-12 col-md-6 d-flex align-items-center justify-content-center text-center pb-2 pb-md-5">
                    <div className="p-3 p-md-5">
                      {value.title && (
                        <h3 className="h1 mb-4 text-uppercase fw-semi-bold">{b2x.formatHtml(value.title)}</h3>
                      )}
                      {value.description && <div className="mb-5">{b2x.formatHtml(value.description)}</div>}
                    </div>
                    <div className="logo-small"></div>
                  </div>
                </b2x.Col>
              ))}
            </b2x.Row>
          )}
        </Container>
        <div className="py-5">
          <Container fluid="always">
            <h3 className="h1 text-uppercase fw-semi-bold text-center mb-5">La nostra crew</h3>
            <AmbassadorList exclude={page?.id} />
          </Container>
        </div>
      </div>
    </Page>
  );
};
