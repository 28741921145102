import './CrewBrandPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { BlockQuote } from '../BlockQuote';
import { Container } from '../Container';
import { CrewBrandPageContentType } from '../contentTypes';
import { CrewHeader } from '../CrewHeader';
import { Section } from '../Section';
import { Page } from './Page';

export interface CrewBrandPageProps {}

export const CrewBrandPage = (props: CrewBrandPageProps) => {
  const page = b2x.usePage<CrewBrandPageContentType>();
  return (
    <Page noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="CrewBrandPage">
        <CrewHeader
          description={page?.content?.body.header?.description}
          image={page?.content?.body.header?.image}
          subtitle={page?.content?.body.header?.subtitle}
          title={page?.content?.body.header?.title}
        />
        <Section className="section-intro mb-5" container="xxl">
          <b2x.Row>
            <b2x.Col className="col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-6 mb-3 mb-md-5 mb-lg-0">
              {page?.content?.body.intro?.image && <b2x.ImageFromContentV1 {...page.content.body.intro.image} fluid />}
            </b2x.Col>
            <b2x.Col className="col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-6 d-flex align-items-center">
              <BlockQuote author={page?.content?.body.intro?.name} size="h3">
                {b2x.formatHtml(page?.content?.body.intro?.description)}
              </BlockQuote>
            </b2x.Col>
          </b2x.Row>
        </Section>
        <Section className="section-product-stats mb-5" container="xxl">
          {page?.content?.body.productsStats?.title && (
            <div className="d-flex justify-content-center align-items-center mb-5">
              <h3 className="h1 text-uppercase mb-0">{b2x.formatHtml(page.content.body.productsStats.title)}</h3>
            </div>
          )}
          <b2x.Row className="product-stats-row">
            {page?.content?.body.productsStats?.productsStatsList?.map((productStat) => (
              <b2x.Col
                className="text-center d-flex align-items-center justify-content-center col-6 col-sm-3 mb-3 mb-md-0"
                key={productStat.contentSectionId}
              >
                <div className="bg-lighter d-flex align-items-center justify-content-center rounded-circle">
                  <div className="p-3">
                    <div className="h1 fw-semi-bold mb-2">{productStat.number}</div>
                    <div className="lh-1">{productStat.title}</div>
                  </div>
                </div>
              </b2x.Col>
            ))}
          </b2x.Row>
        </Section>
        <Section className="section-media mb-3 mb-md-5" container="xxl">
          <b2x.Row className="mb-5">
            <b2x.Col className="col-12 col-md-6 mb-3 mb-md-0">
              <b2x.Row>
                <b2x.Col className="mb-3 mb-md-4" size={12}>
                  <b2x.ImageFromContentV1 {...page?.content?.body.media?.imageTopLeft} fluid />
                </b2x.Col>
                <b2x.Col size={12}>
                  <b2x.ImageFromContentV1 {...page?.content?.body.media?.imageBottomLeft} fluid />
                </b2x.Col>
              </b2x.Row>
            </b2x.Col>
            <b2x.Col className="col-12 col-md-6">
              <b2x.ImageAsBackgroundFromContentV1
                {...page?.content?.body.media?.imageRight}
                className="d-none d-md-block"
              />
              <b2x.ImageFromContentV1 {...page?.content?.body.media?.imageRight} className="d-md-none" fluid />
            </b2x.Col>
          </b2x.Row>
          {page?.content?.body.media?.description && (
            <b2x.Row className="justify-content-center">
              <b2x.Col className="col-lg-6">
                <BlockQuote size="h3" textCenter>
                  {b2x.formatHtml(page.content.body.media.description)}
                </BlockQuote>
              </b2x.Col>
            </b2x.Row>
          )}
        </Section>
        <Section className="section-our-values bg-lighter" container="xxl" withDots>
          <b2x.Row>
            <b2x.Col className="col-12 col-lg-10 offset-lg-1">
              {page?.content?.body.ourValues?.title && (
                <h3 className="h1 text-uppercase mb-3 mb-md-5">{b2x.formatHtml(page.content.body.ourValues.title)}</h3>
              )}
              {page?.content?.body.ourValues?.ourValuesList?.map((item, index) => (
                <b2x.Row className="our-values-row g-lg-5" key={item.contentSectionId}>
                  <b2x.Col
                    className={classnames(
                      index % 2 === 0 ? 'order-1' : 'order-2',
                      'col-12 col-sm-6 py-md-3 py-lg-0 mb-3 mb-md-0'
                    )}
                  >
                    <b2x.ImageFromContentV1 {...item.image} fluid />
                  </b2x.Col>
                  <b2x.Col
                    className={classnames(
                      index % 2 === 0 ? 'order-2' : 'order-1',
                      'col-12 col-sm-6 mb-3 mb-md-0 d-flex align-items-center py-md-3 py-lg-0'
                    )}
                  >
                    <div>
                      <div className="fw-bold">{item.title}</div>
                      <div>{b2x.formatHtml(item.description)}</div>
                    </div>
                  </b2x.Col>
                </b2x.Row>
              ))}
            </b2x.Col>
          </b2x.Row>
        </Section>
        <Container />
      </div>
    </Page>
  );
};
