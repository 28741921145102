import './SliderC.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { SliderCContentSection } from '../contentTypes';
import { Fetching } from '../Fetching';
import { ProductTile } from '../ProductTile';
import { Slider } from '../Slider';
import { SliderButton } from '../SliderButton';

export interface SliderCProps extends SliderCContentSection {
  products?: Array<b2x.ProductApiDto>;
}

export const SliderC = React.memo(({ description, img, mobileTitle, products, subtitle, title }: SliderCProps) => {
  return (
    <div className="SliderC">
      <div className="row">
        <div className="col-12 d-lg-none">
          <h3 className="text-uppercase fw-bold mb-0">{mobileTitle}</h3>
          <h4 className="text-uppercase text-primary fw-semi-bold mb-4">{title}</h4>
        </div>
        <div className="before-product-slider col-md-6 col-lg-4 col-xxl-3 mb-4 mb-lg-0">
          <div className="before-product-slider-image">
            <b2x.ImageFromContentV1 {...img} />
          </div>
          <div className="before-product-slider-content bg-black text-white p-4 ms-auto me-auto mt-n5">
            <p>
              <span className="fw-semi-bold">{subtitle}</span>
              <br />
              {description}
            </p>
          </div>
        </div>
        <b2x.EqualHeight>
          <div className="col-md-6 col-lg-8 col-xxl-9 p-0 pe-lg-0 ps-lg-3">
            <h3 className="d-none d-lg-block text-uppercase h1 text-primary fw-bold mb-4">{title}</h3>
            <div className="slider-container">
              <Fetching item={products}>
                <Slider
                  className="product-slider next-slide-3"
                  loop
                  navigation
                  responsive={{
                    lg: {
                      slidesPerView: 2.15,
                    },
                    xl: {
                      slidesPerView: 3.25,
                    },
                  }}
                  sliderNextEl={'.SliderC .swiper-button-next'}
                  sliderPrevEl={'.SliderC .swiper-button-prev'}
                  slidesPerView={1.15}
                  spaceBetween={20}
                >
                  {products?.map((product, index) => (
                    <SwiperSlide key={product.id}>
                      <ProductTile product={product} />
                    </SwiperSlide>
                  ))}
                </Slider>
              </Fetching>
              <SliderButton direction="left" size="large" style={{ visibility: products ? 'visible' : 'hidden' }} />
              <SliderButton direction="right" size="large" style={{ visibility: products ? 'visible' : 'hidden' }} />
            </div>
          </div>
        </b2x.EqualHeight>
      </div>
    </div>
  );
});
SliderC.displayName = 'SliderC';
