import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext, useAppStaticContext } from '../AppContext';

export interface PageProps extends b2x.PageProps {
  backgroundColor?: string;
  backgroundImage?: b2x.contentTypes.ImageContentSectionV1;
  children?: React.ReactNode;
  greyBackground?: boolean;
  headerCheckout?: boolean;
  minimalFooter?: boolean;
  noPaddingBottom?: boolean;
  noPaddingTop?: boolean;
  transparentHeader?: boolean;
}

export const Page = ({
  backgroundColor,
  backgroundImage,
  children,
  className,
  greyBackground = false,
  headerCheckout = false,
  minimalFooter = false,
  noPaddingBottom = false,
  noPaddingTop = false,
  transparentHeader = false,
  ...otherProps
}: PageProps) => {
  const { pageReady } = b2x.useAppContext();
  const { headerHeight } = useAppContext();
  const { setGreyBackgroundPage, setHeaderCheckout, setMinimalFooter, setTransparentHeader } = useAppStaticContext();

  React.useEffect(() => {
    pageReady && setTransparentHeader(transparentHeader);
    pageReady && setMinimalFooter(minimalFooter);
    pageReady && setHeaderCheckout(headerCheckout);
    pageReady && setGreyBackgroundPage(greyBackground);
  }, [
    greyBackground,
    minimalFooter,
    headerCheckout,
    setHeaderCheckout,
    pageReady,
    setGreyBackgroundPage,
    setMinimalFooter,
    transparentHeader,
    setTransparentHeader,
    backgroundColor,
  ]);

  React.useEffect(() => {
    if (greyBackground) {
      document.body.classList.add('bg-lighter');
    } else {
      document.body.classList.remove('bg-lighter');
    }
  }, [greyBackground]);

  React.useEffect(() => {
    if (backgroundColor) {
      document.body.style.backgroundColor = backgroundColor;
    } else {
      document.body.style.backgroundColor = '';
    }
  }, [backgroundColor]);

  return (
    <b2x.Page {...otherProps} className={classnames(className, 'h-100')}>
      <div className="Page h-100 position-relative">
        {backgroundImage && (
          <div className="position-absolute h-100 w-100">
            <b2x.ImageAsBackgroundFromContentV1 {...backgroundImage} />
          </div>
        )}
        <div
          className={classnames(
            'position-relative h-100',
            'py-3 py-xl-5',
            { 'bg-lighter': greyBackground },
            { 'pt-0 pt-xl-0': noPaddingTop },
            { 'pb-0 pb-xl-0': noPaddingBottom }
          )}
          style={{
            marginBottom: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
            top: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
          }}
        >
          {children}
        </div>
      </div>
    </b2x.Page>
  );
};
