import './CrewHeader.scss';

import { b2x } from '@b2x/react/src';

import { Container } from './Container';
import { Section } from './Section';

export interface CrewHeaderProps {
  description?: string;
  image?: b2x.contentTypes.ImageContentSectionV1;
  subtitle?: string;
  title?: string;
}

export const CrewHeader = ({ description, image, subtitle, title }: CrewHeaderProps) => {
  const menu = b2x.useMenu('MENU_CREW');
  return (
    <Section className="CrewHeader">
      <div className="wallpaper position-relative">
        {image && <b2x.ImageFromContentV1 {...image} fluid />}
        <div className="text-container position-absolute top-0 w-100 h-100 d-flex align-items-center justify-content-center text-center">
          <div>
            {title && <h1 className="text-primary display-1 fw-bold">{title}</h1>}
            {subtitle && <h3 className="text-white display-5 fw-light">{subtitle}</h3>}
          </div>
        </div>
      </div>
      <Container>
        {menu?.children && (
          <div className="d-flex justify-content-center my-5">
            <ul className="nav nav-pills">
              {menu.children.map((item) => (
                <li className="nav-item" key={item.id}>
                  <b2x.router.NavLink className="nav-link text-black fw-semi-bold text-uppercase" to={item.link}>
                    {item.label}
                  </b2x.router.NavLink>
                </li>
              ))}
            </ul>
          </div>
        )}
        {description && <div className="mb-5 text-center">{b2x.formatHtml(description)}</div>}
      </Container>
    </Section>
  );
};
