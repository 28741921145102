import './MasterclassPage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { MasterclassEventPageContentType } from '../contentTypes';
import { PageHeader } from '../PageHeader';
import { Page } from './Page';

export interface MasterclassEventPageProps {}

export const MasterclassEventPage = (props: MasterclassEventPageProps) => {
  const page = b2x.usePage<MasterclassEventPageContentType>();

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="masterclass-event-page">
        {page?.content?.body.title && (
          <PageHeader breadcrumb hiddenBreadcrumbLastItem small title={page.content.body.title} />
        )}
        <Container>
          {page?.content?.body.date && (
            <h6 className="text-center text-dark text-capitalize fw-light small mt-2 mb-5">
              {b2x.formatDateFromContent(page.content.body.date, 'dddd L')}
            </h6>
          )}
          {page?.content?.body.contentList?.map((content) => (
            <div key={content.contentSectionId}>
              {content.html && <div className="mb-5">{b2x.formatHtml(content.html)}</div>}
              {content.image && (
                <div className="mb-5">
                  <b2x.ImageFromContentV1 {...content.image} fluid />
                </div>
              )}
            </div>
          ))}
        </Container>
      </div>
    </Page>
  );
};
