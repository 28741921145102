import './PayPalCheckout.scss';

import { b2x } from '@b2x/react/src';

import { Button } from './Button';
import { t } from './i18n/i18n';

export type PayPalCheckoutProps = b2x.PayPalCheckoutProps;

export const PayPalCheckout = (props: PayPalCheckoutProps) => (
  <b2x.PayPalCheckout {...props} customComponent={CustomPayPalCheckout} />
);

const CustomPayPalCheckout = ({ ...usePayPalCheckoutProps }: PayPalCheckoutProps) => {
  const {
    buttonsComponentProps,
    cardFieldsProviderProps,
    cvvFieldProps,
    expiryFieldProps,
    handleCancel,
    nameFieldProps,
    numberFieldProps,
    scriptProviderOptions,
  } = b2x.usePayPalCheckout(usePayPalCheckoutProps);

  return (
    <b2x.PayPalScriptProvider options={scriptProviderOptions}>
      <b2x.PayPalCardFieldsProvider
        {...cardFieldsProviderProps}
        style={{
          '.invalid': {
            border: '1px solid #e2081e',
            'box-shadow': 'none',
          } as never,
          ':focus': {
            border: 'solid 1px #fddb92',
            'box-shadow': '0 0 0 0.25rem rgba(250, 183, 37, 0.25)',
          } as never,
          ':focus.invalid': {
            border: 'solid 1px #fddb92',
            'box-shadow': '0 0 0 0.25rem rgba(250, 183, 37, 0.25)',
          } as never,
          body: {
            padding: '8px',
          },
          input: {
            border: '1px solid #787878',
            'border-radius': '0px',
            color: '#000',
            'font-size': '16px',
            'line-height': '1.625',
            padding: '0.375rem 0.75rem',
          } as never,
        }}
      >
        <b2x.PayPalButtons {...buttonsComponentProps} />
        <hr />
        <div style={{ margin: '-8px', marginBottom: '8px' }}>
          <b2x.PayPalNameField {...nameFieldProps} />
          <b2x.PayPalNumberField {...numberFieldProps} />
          {/* {cardFieldsState?.errors.includes('INVALID_NUMBER') && <small>!!!</small>} */}
          <b2x.Div display="flex">
            <div style={{ width: '100%' }}>
              <b2x.PayPalExpiryField {...expiryFieldProps} />
            </div>
            <div style={{ width: '100%' }}>
              <b2x.PayPalCVVField {...cvvFieldProps} />
            </div>
          </b2x.Div>
        </div>
        <b2x.Div marginBottom={3}>
          <small>{b2x.formatHtml(t('misc.payPalCheckout.advanced.privacyNotice'))}</small>
        </b2x.Div>
        <b2x.Div display="flex" gap={3} justifyContent="end">
          <Button label={t('misc.payPalCheckout.form.buttons.cancel')} onClick={handleCancel} />
          <SubmitPayment />
        </b2x.Div>
      </b2x.PayPalCardFieldsProvider>
    </b2x.PayPalScriptProvider>
  );
};

const SubmitPayment = () => {
  const { handleClick, isPaying } = b2x.usePayPalCheckoutSubmitButton({});
  return (
    <Button disabled={isPaying} onClick={handleClick} variant="primary">
      {t('misc.payPalCheckout.form.buttons.submit')}
    </Button>
  );
};
