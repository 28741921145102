import React from 'react';
import { DefaultValues, FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { ButtonProps } from '../Button';
import { DropdownProps } from '../Dropdown';
import { t } from '../i18n/i18n';
import { createUUID, validateEmail as _validateEmail } from '../util';
import { FormStaticContext } from './FormContext';
// import { FormContext } from './FormContext';

export interface FormProps<FormValues extends FieldValues>
  extends Omit<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onSubmit'> {
  //   action?: string;
  //   autoComplete?: 'off';
  //   children:
  //     | React.ReactNode
  //     | ((props: { formContext: FormContextInterface; formik: FormikProps<FormValues> }) => React.ReactNode);
  //   className?: string;
  //   closeModalOnSuccess?: boolean;
  //   id?: string;
  //   innerRef?: React.RefObject<HTMLFormElement>;
  //   method?: 'POST' | 'GET';
  //   onChange?(values: FormValues, formikHelpers: FormikHelpers<FormValues>): void;
  //   onFieldsChange?: FormFieldsChangeHandlerProps<FormValues>;
  //   onMount?(values: FormValues, formikHelpers: FormikHelpers<FormValues>): void;
  //   onReset?(values: FormValues, formikHelpers: FormikHelpers<FormValues>): Promise<unknown>;
  //   onSubmit?(values: FormValues, formikHelpers: FormikHelpers<FormValues>): Promise<unknown>;
  //   persist?: boolean;
  //   resetOnSuccess?: boolean;
  //   showValidFeedback?: boolean;
  //   submitOnChange?: boolean;
  //   validationSchema?: ValidationSchema;
  form: UseFormReturn<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
}

export const Form = <FormValues extends FieldValues>({
  form,
  onSubmit,
  ...otherProps
}: FormProps<FormValues>): React.ReactElement => {
  const id = React.useMemo(() => `form-${createUUID()}`, []);

  //   const persistedInitialValues = persist ? storage.getObject<FormValues>(`${formId}-values`) : undefined;

  //   const closeModal = useModalCloser();

  //   const handleSubmit = React.useCallback(
  //     (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
  //       if (onSubmit) {
  //         const result = onSubmit(values, formikHelpers);
  //         Promise.resolve(result)
  //           .then(() => {
  //             resetOnSuccess && formikHelpers.resetForm();
  //             // Se sono dentro un modale, lo chiudo
  //             closeModalOnSuccess && closeModal();
  //           })
  //           .finally(() => {
  //             formikHelpers.setSubmitting(false);
  //           });
  //         return result;
  //       } else {
  //         console.log(values);
  //         alert(JSON.stringify(values, null, 2));
  //         formikHelpers.setSubmitting(false);
  //       }
  //     },
  //     [closeModal, closeModalOnSuccess, onSubmit, resetOnSuccess]
  //   );

  //   const handleReset = React.useCallback((values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
  //     console.log('handleReset');
  //   }, []);

  //   const formik = useFormik({
  //     ...formikProps,
  //     initialValues: persistedInitialValues ?? initialValues,
  //     onReset: handleReset,
  //     onSubmit: handleSubmit,
  //     validationSchema: validationSchema ? yup.object(validationSchema) : undefined,
  //   });

  return (
    <FormStaticContext id={id}>
      <FormProvider {...form}>
        {/* <FormContext<FormValues>
        id={id}
        form={form}
        // showValidFeedback={showValidFeedback}
        // validationSchema={validationSchema ? yup.object(validationSchema) : undefined}
        // values={formik.values}
      > */}
        {/* {(formContext) => <>{typeof children === 'function' ? children({ formContext, formik }) : children}</>} */}
        <form {...otherProps} noValidate onSubmit={form.handleSubmit(onSubmit)} />
        {/* </FormContext> */}
        {/* {persist && <FormPersister storageKey={`${formId}-values`} />}
      {submitOnChange && <FormOnChangeSubmitter />}
      {onChange && <FormChangeHandler onChange={onChange} />}
      {onMount && <FormMountHandler onMount={onMount} />}
      {onFieldsChange &&
        Object.entries(onFieldsChange).map(
          ([name, callback]) => callback && <FormFieldChangeHandler callback={callback} key={name} name={name} />
        )} */}
      </FormProvider>
    </FormStaticContext>
  );
};

// export type formikString = string;
// export type formikBooleanAsString = 'true' | 'false' | '';
// export type formikBoolean = boolean | 'true' | 'false' | 0 | 1;
// export type formikNumber = number | '';
// export type formikDate = string;
// export type formikDateTime = string;
// export type formikFile = string;
// export type formikEnum<T> = NonNullable<T> | '';

// export const getInitialString = (value?: string): formikString => value ?? '';
// export const getInitialBooleanAsString = (value?: boolean): formikBooleanAsString =>
//   value === true ? 'true' : value === false ? 'false' : '';
// export const getInitialBoolean = (value?: boolean): formikBoolean => value ?? false;
// export const getInitialNumber = (value?: number): formikNumber => value ?? '';
// export const getInitialDate = (value?: Date): formikDate => formatDateForInput(value);
// export const getInitialDateTime = (value?: Date): formikDateTime => formatDateTimeForInput(value);
// export const getInitialEnum = <T,>(value?: T): formikEnum<T> => value ?? '';

// export const getFormikStringValue = (value: formikString): string => value;
// export const getFormikBooleanAsStringValue = (value: formikBooleanAsString): boolean =>
//   value === 'true' ? true : false;
// export const getFormikBooleanValue = (value: formikBoolean): boolean => (value ? true : false);
// export const getFormikNumberValue = (value: formikNumber): number | undefined => (value === '' ? undefined : value);
// export const getFormikDateValue = (value: formikDate): Date | undefined => parseDateFromInput(value);
// export const getFormikDateTimeValue = (value: formikDateTime): Date | undefined => parseDateTimeFromInput(value);
// export const getFormikEnumValue = <T,>(value: formikEnum<T>): T | undefined => (value === '' ? undefined : value);

export const isSubmitButtonDisabled = () => {
  return false;
};

export const isResetButtonDisabled = () => {
  return false;
};

// export interface CommonFieldProps<T, V> {
//   autoFocus?: boolean;
//   className?: string;
//   disabled?: boolean;
//   // formFieldRef?: React.RefObject<T>;
//   inhibitFormikOnChange?: boolean;
//   innerRef?: React.RefObject<T>;
//   name: string;
//   onBlur?(event: React.FocusEvent<T, Element>): void;
//   onChange?(event: React.ChangeEvent<T>): void;
//   onClick?: React.MouseEventHandler<T>;
//   onFocus?(event: React.FocusEvent<T, Element>): void;
//   onFormikChange?(value: V, formik: FormikContextType<unknown>): void;
//   onFormikInitAndChange?(value: V, formik: FormikContextType<unknown>, useCase: 'init' | 'change'): void;
//   readOnly?: boolean;
//   size?: 'small' | 'large';
//   style?: React.CSSProperties;
//   title?: string;
// }

export type HTMLAutocomplete = 'off' | 'new-password' | 'current-password';

// export interface UseField<T> {
//   b2xHelpers: {
//     handleChange(event: React.ChangeEvent<T>): void;
//     isInvalid: boolean;
//     isValid: boolean;
//   };
//   field: FieldInputProps<string | number | ReadonlyArray<string> | undefined>;
//   helpers: FieldHelperProps<string | number | ReadonlyArray<string> | undefined>;
//   meta: FieldMetaProps<string | number | ReadonlyArray<string> | undefined>;
// }

// export const useField = <T, V>(
//   props: CommonFieldProps<T, V>,
//   formikProps: FieldHookConfig<string | number | ReadonlyArray<string> | undefined>
// ): UseField<T> => {
//   const [field, meta, helpers] = formikUseField(formikProps);
//   const { showValidFeedback } = useFormContext();
//   const formik = useFormikContext();

//   const isValid = showValidFeedback !== undefined && meta.touched && !meta.error;
//   const isInvalid = meta.touched && !!meta.error;

//   const handleChange = React.useCallback(
//     (event: React.ChangeEvent<T>) => {
//       if (!props.inhibitFormikOnChange) {
//         field.onChange(event);
//       }
//       // Eventuale applicazione di una funzione "valueTransformer" che setta ad esempio numeri invece di stringhe
//       // helpers.setValue(parseInt((event.target as any).value));
//       if (props.onChange) {
//         props.onChange.call(undefined, event);
//       }
//     },
//     [field, props.inhibitFormikOnChange, props.onChange]
//   );

//   const b2xHelpers = { handleChange, isInvalid, isValid };

//   const { values } = formik;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   const value = _.get(values as any, field.name);

//   const [lastValueForOnFormikChange, setLastValueForOnFormikChange] = React.useState<unknown>(value);

//   React.useEffect(() => {
//     if (props.onFormikChange) {
//       // console.log(`fieldValue: ${field.value}`, `values.name: ${value}`, `lastValue: ${lastValue}`);
//       const valueEqualLastValue = _.isEqual(lastValueForOnFormikChange, value);
//       // questo controllo agiuntivo serve per i radio, altrimenti con un blocco di 6 radio la callback partirebbe 6 volte.
//       const valueEqualFieldValue = _.isEqual(field.value, value);

//       if (!valueEqualLastValue) {
//         setLastValueForOnFormikChange(value);
//       }

//       if (!valueEqualLastValue && valueEqualFieldValue) {
//         // Metto la wait in quanto altrimenti il lastValue dava anora il valore non aggiornato nella set poco sopra.
//         wait(100).then(() => {
//           props.onFormikChange && props.onFormikChange.call(undefined, value, formik);
//         });
//       }
//     }
//   }, [field.value, formik, lastValueForOnFormikChange, props.onFormikChange, value]);

//   const [lastValueForOnFormikInitAndChange, setLastValueForOnFormikInitAndChange] = React.useState<unknown>();

//   React.useEffect(() => {
//     if (props.onFormikInitAndChange) {
//       // console.log(`fieldValue: ${field.value}`, `values.name: ${value}`, `lastValue: ${lastValue}`);
//       const valueEqualLastValue = _.isEqual(lastValueForOnFormikInitAndChange, value);
//       // questo controllo agiuntivo serve per i radio, altrimenti con un blocco di 6 radio la callback partirebbe 6 volte.
//       const valueEqualFieldValue = _.isEqual(field.value, value);

//       if (!valueEqualLastValue) {
//         setLastValueForOnFormikInitAndChange(value);
//       }

//       if (!valueEqualLastValue && valueEqualFieldValue) {
//         // Metto la wait in quanto altrimenti il lastValue dava anora il valore non aggiornato nella set poco sopra.
//         wait(100).then(() => {
//           props.onFormikInitAndChange &&
//             props.onFormikInitAndChange.call(
//               undefined,
//               value,
//               formik,
//               lastValueForOnFormikInitAndChange === undefined ? 'init' : 'change'
//             );
//         });
//       }
//     }
//   }, [field.value, formik, lastValueForOnFormikInitAndChange, props.onFormikInitAndChange, value]);

//   return {
//     b2xHelpers,
//     field,
//     helpers,
//     meta,
//   };
// };

// interface FormPersisterProps {
//   storageKey: string;
// }

// const FormPersister = <FormValues extends FormikValues>({ storageKey }: FormPersisterProps) => {
//   const { values } = useFormikContext<FormValues>();
//   const [debouncedValues] = useDebounce(values, 300);

//   React.useEffect(() => {
//     storage.setObject(storageKey, debouncedValues);
//   }, [debouncedValues, storageKey]);

//   return null;
// };

// interface FormOnChangeSubmitterProps {}

// const FormOnChangeSubmitter = <FormValues extends FormikValues>(props: FormOnChangeSubmitterProps) => {
//   const { initialValues, submitForm, values } = useFormikContext<FormValues>();
//   const [lastValues, setLastValues] = React.useState<FormValues>(values);

//   React.useEffect(() => {
//     const valuesEqualLastValues = _.isEqual(lastValues, values);
//     const valuesEqualInitialValues = values === initialValues;

//     if (!valuesEqualLastValues) {
//       setLastValues(values);
//     }

//     if (!valuesEqualLastValues && !valuesEqualInitialValues) {
//       submitForm();
//     }
//   }, [initialValues, lastValues, submitForm, values]);

//   return null;
// };

// interface FormMountHandlerProps<FormValues> {
//   onMount(values: FormValues, formikHelpers: FormikHelpers<FormValues>): void;
// }

// const FormMountHandler = <FormValues extends FormikValues>({ onMount }: FormMountHandlerProps<FormValues>) => {
//   const formik = useFormikContext<FormValues>();
//   const formikHelpers = useFormikHelpers<FormValues>();

//   React.useEffect(() => {
//     onMount(formik.initialValues, formikHelpers);
//   }, [formik.initialValues, formikHelpers, onMount]);

//   return null;
// };

// interface FormChangeHandlerProps<FormValues> {
//   onChange(values: FormValues, formikHelpers: FormikHelpers<FormValues>): void;
// }

// const FormChangeHandler = <FormValues extends FormikValues>({ onChange }: FormChangeHandlerProps<FormValues>) => {
//   const formik = useFormikContext<FormValues>();
//   const formikHelpers = useFormikHelpers<FormValues>();

//   const values = formik.values;
//   const [lastValues, setLastValues] = React.useState<FormValues>(values);

//   React.useEffect(() => {
//     const valuesEqualLastValues = _.isEqual(lastValues, values);

//     if (!valuesEqualLastValues) {
//       setLastValues(values);
//     }

//     if (!valuesEqualLastValues) {
//       onChange(values, formikHelpers);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [values]);

//   return null;
// };

// export interface FormFieldChangeHandlerProps<FormValues> {
//   callback(values: FormValues, formikHelpers: FormikHelpers<FormValues>): void;
//   name: keyof FormValues;
// }

// const FormFieldChangeHandler = <FormValues extends FormikValues>({
//   callback,
//   name,
// }: FormFieldChangeHandlerProps<FormValues>) => {
//   const formik = useFormikContext<FormValues>();
//   const formikHelpers = useFormikHelpers<FormValues>();
//   const { values } = formik;
//   const value = _.get(values, name);
//   const [lastValue, setLastValue] = React.useState<unknown>(value);

//   React.useEffect(() => {
//     const valueEqualLastValue = _.isEqual(lastValue, value);

//     if (!valueEqualLastValue) {
//       setLastValue(value);
//     }

//     if (!valueEqualLastValue) {
//       callback(formik.values, formikHelpers);
//     }

//     // Non so bene il perchè, ma senza questo fix asincrono formik non aggiorna bene lo stato del form.
//     wait(0).then(() => {
//       formikHelpers.setFieldTouched('', true);
//     });
//     // Disabilito exhaustive-deps, voglio tracciare solo il cambio del valore.
//     // Equestro va in loop se cambii velocemente la variante colore.
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [value]);

//   return null;
// };

// // export const isFieldMounted = (fieldName: string, ref: React.RefObject<HTMLFormElement>) =>
// //   ref.current?.querySelector(`[name="${fieldName}"]`) !== null;

// const useFormikHelpers = <FormValues extends FormikValues>(): FormikHelpers<FormValues> => {
//   const formik = useFormikContext<FormValues>();

//   const formikHelpers = React.useMemo<FormikHelpers<FormValues>>(
//     () => ({
//       resetForm: formik.resetForm,
//       setErrors: formik.setErrors,
//       setFieldError: formik.setFieldError,
//       setFieldTouched: formik.setFieldTouched,
//       setFieldValue: formik.setFieldValue,
//       setFormikState: formik.setFormikState,
//       setStatus: formik.setStatus,
//       setSubmitting: formik.setSubmitting,
//       setTouched: formik.setTouched,
//       setValues: formik.setValues,
//       submitForm: formik.submitForm,
//       validateField: formik.validateField,
//       validateForm: formik.validateForm,
//     }),
//     [
//       formik.resetForm,
//       formik.setErrors,
//       formik.setFieldError,
//       formik.setFieldTouched,
//       formik.setFieldValue,
//       formik.setFormikState,
//       formik.setStatus,
//       formik.setSubmitting,
//       formik.setTouched,
//       formik.setValues,
//       formik.submitForm,
//       formik.validateField,
//       formik.validateForm,
//     ]
//   );

//   return formikHelpers;
// };

// export const getFormikHelpers = <FormValues extends FormikValues>(
//   formik: FormikContextType<FormValues>
// ): FormikHelpers<FormValues> => ({
//   resetForm: formik.resetForm,
//   setErrors: formik.setErrors,
//   setFieldError: formik.setFieldError,
//   setFieldTouched: formik.setFieldTouched,
//   setFieldValue: formik.setFieldValue,
//   setFormikState: formik.setFormikState,
//   setStatus: formik.setStatus,
//   setSubmitting: formik.setSubmitting,
//   setTouched: formik.setTouched,
//   setValues: formik.setValues,
//   submitForm: formik.submitForm,
//   validateField: formik.validateField,
//   validateForm: formik.validateForm,
// });

export type FormButtonProps = Omit<ButtonProps<string, string, number>, 'iconEnd' | 'iconStart'>;
export type FormDropdownProps = Omit<DropdownProps<string, string, number>, 'iconEnd' | 'iconStart'>;

export interface BaseUseFormProps<FormValues, ValidationSchemaSelector> {
  // className?: string;
  //  disableLabel?: boolean;
  defaultValues?: DefaultValues<FormValues>;
  onCancel?(): void;
  onSuccess?(): void;
  validationSchemaSelector?: ValidationSchemaSelector;
}

export const validateEmail = {
  email: (value: string) => _validateEmail(value) || t('yup.string.email'),
};
